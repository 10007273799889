import form from "../../../utils/form";

function initialState() {
    return {
        item: {
            name: null,
        },
        form: {
            name: null,
        },
        statuses: [
            "Queued",
            "Failed",
            "Processing",
            "Completed"
        ],
        errors: {},
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    errors: state => state.errors,
    loading: state => state.loading,
    statuses: state => state.statuses
};

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true);
        commit('setErrors', {});
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)),{indices: true});
            axios.post('catalog/downloads', params)
                .then(response => {
                    commit('resetForm');
                    resolve(response.data);
                })
                 .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    updateData({ commit, state }) {
        commit('setLoading', true);
        commit('setErrors', {});
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)), {indices: true});
            params.set('_method', 'PUT');
            axios.post(`catalog/downloads/${state.item.id}`, params)
                .then(response => {
                    resolve(response.data);
                })
                 .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    fetchData({ commit, dispatch, state }, id) {
        axios.get(`catalog/downloads/${id}`)
            .then(response => {
                commit('setItem', _.get(response.data, "data", {}));
            });
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
    setItemProperty({ commit }, {key, value}) {
        commit('setItemProperty', {key, value})
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setErrors(state, value) {
        state.errors = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    resetForm(state) {
        state.file = null;
        _.merge(state.item, state.form);
    },
    setItemProperty(state, {key, value}){
        _.set(state.item, key, value);
    },
    setStatuses(state, value) {
        state.statuses = value
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};

