function initialState() {
    return {
        item: {
            customer:{},
            basicProfile: {}
        },
        errors: {},
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    errors: state => state.errors,
    loading: state => state.loading,
};

const actions = {

    fetchData({ commit, dispatch }) {
        axios.get('/account/profile')
            .then(response => {
                commit('setItem', response.data);
            });
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },

    resetState({ commit }) {
        commit('resetState');
    }
};

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setErrors(state, value) {
        state.errors = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },

};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};

