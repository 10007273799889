import form from '../../../utils/form';

function initialState() {
    return {
        item: {
            "site-title": null,
            'site-description': null,
            "site-email": null,
            "default-timezone": null,
            "records-per-page": null,
            'download-orders': false,
            'email-downloaded-orders': false,
            'download-frequency': 2,
            'orders-email': null,
        },
        form: {
            "site-title": null,
            'site-description': null,
            "site-email": null,
            "default-timezone": null,
            "records-per-page": null,
            'download-orders': false,
            'email-downloaded-orders': false,
            'download-frequency': 2,
            'orders-email': null
        },
        timezones: [],
        errors: {},
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    errors: state => state.errors,
    loading: state => state.loading,
    timezones: state => state.timezones
};

const actions = {

    updateData({ commit, state, dispatch },section) {
        commit('setLoading', true);
        commit('setErrors', {});
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)));
            axios.post(`settings/${section}`, params)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    fetchData({ commit, dispatch}, section) {
        axios.get(`settings/${section}`)
            .then(response => {
                if(!_.isEmpty(response.data)){
                    commit('setItem', _.get(response, "data.settings", {}));
                }
            });
    },
    fetchOptions({commit}, section) {
        axios.get(`settings/${section}/options`)
            .then(response => {
                if(!_.isEmpty(response.data)){
                    commit('setTimeZones', _.get(response, "data.data.timezones", []));
                }
            });
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
    setItemProperty({ commit }, {key, value}) {
        commit('setItemProperty', {key, value})
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setTimeZones(state, timezones) {
        state.timezones = timezones
    },
    setItemProperty(state, {key, value}){
        state.item[key] = value;
    },
    setErrors(state, value) {
        state.errors = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },

};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};

