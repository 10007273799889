import Vue from 'vue';

Vue.filter('dateFormat', function (value, format) {
    if(!(typeof moment === 'function')) return value;
    let date = moment(value);
    if(date.isValid()){
        if(typeof format !== "undefined")
            return date.format(format);
        else
            return date.format()
    }
    return value;
});

Vue.filter('currency', function (value, currency) {
    if(currency == 'INR'){
        return '₹ ' + parseFloat(value).toFixed(2);
    }
    if(currency == 'USD'){
        return '$ ' + parseFloat(value).toFixed(2);
    }
    return null;
});