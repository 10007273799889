var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { attrs: { "aria-label": "Page navigation" } }, [
    _c("p", [
      _vm._v(
        "Page " +
          _vm._s(_vm.currentPage) +
          " of " +
          _vm._s(_vm.totalPages) +
          ", showing " +
          _vm._s(_vm.itemsCount) +
          " records out of " +
          _vm._s(_vm.totalItems) +
          " total, starting on record " +
          _vm._s(_vm.start) +
          ", ending on " +
          _vm._s(_vm.end) +
          " "
      )
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "pagination font-s-12" },
      [
        _c(
          "li",
          {
            staticClass: "page-item pagination-page-nav",
            class: !_vm.hasFirst ? _vm.disabledClass : ""
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                on: {
                  click: function($event) {
                    return _vm.selectPage(1)
                  }
                }
              },
              [_vm._v("First")]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "page-item pagination-page-nav",
            class: !_vm.hasPrevious ? _vm.disabledClass : ""
          },
          [
            _c(
              "a",
              { staticClass: "page-link", on: { click: _vm.previousPage } },
              [_vm._v("Previous")]
            )
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.pagerRange, function(page) {
          return _vm.hasPageRange
            ? _c(
                "li",
                {
                  staticClass: "page-item pagination-page-nav",
                  class: _vm.getActiveClass(page)
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      on: {
                        click: function($event) {
                          return _vm.selectPage(page)
                        }
                      }
                    },
                    [_vm._v(_vm._s(page))]
                  )
                ]
              )
            : _vm._e()
        }),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "page-item pagination-page-nav",
            class: !_vm.hasNext ? _vm.disabledClass : ""
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                on: {
                  click: function($event) {
                    return _vm.nextPage()
                  }
                }
              },
              [_vm._v("Next")]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "page-item pagination-page-nav",
            class: !_vm.hasLast ? _vm.disabledClass : ""
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                on: {
                  click: function($event) {
                    return _vm.selectPage(_vm.pager.last_page)
                  }
                }
              },
              [_vm._v("Last")]
            )
          ]
        ),
        _vm._v(" "),
        _c("li", { staticClass: "page-item pagination-page-nav ml-auto" }, [
          _c("div", { staticClass: "input-group mb-2 mr-sm-2" }, [
            _c("input", {
              ref: "goto",
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "Page No." },
              domProps: { value: _vm.currentPage }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "input-group-append" }, [
              _c(
                "button",
                { staticClass: "input-group-text", on: { click: _vm.goto } },
                [_vm._v("Go")]
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }