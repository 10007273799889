<template>
    <div class="container-fluid">
        <filterable :endpoint="'catalog/downloads'" :params="this.query" :sortable="sortable" :filter-group="filters" ref="filterable">
            <template slot="title">
                <h6 class="m-0 font-weight-bold text-primary float-left"><i class="fa fa-cogs mr-2"></i>Downloads</h6>
            </template>
            <template slot="filter-nav" slot-scope="{ sortingClass, sortData }">
                <div class="float-right">
                    <ul class="nav nav-pills reports-top-tab">
                        <li class="ml-auto font-s-13 d-table dropdown pt-2">
                            <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="text-dark d-block mt-1 dropdown-toggle">
                                Sort by
                            </a>
                            <div aria-labelledby="navbarDropdown" class="sorting dropdown-menu">
                                <a v-for="s in sortable" class="dropdown-item" :class="sortingClass(s.value)" @click="sortData(s.value)">{{s.name}}</a>
                            </div>
                        </li>
                        <li class="ml-3 font-s-13 d-table pt-2">
                            <a href="#collapseFilter" data-toggle="collapse" class="btn-filter text-dark d-block mt-1"><i class="fa fa-filter"></i> Filters</a>
                        </li>
                        <li class="ml-3 font-s-13 d-table pt-2">
                            <router-link :to="{ name: 'catalog.downloads.create'}" class="text-dark d-block mt-1"><i class="fa fa-plus"></i> Add new download</router-link>
                        </li>
                    </ul>
                </div>
            </template>
            <template slot-scope="{ items, remove, sortingClass, sortData }" slot="content">
                <table class="table table-small mb-0">
                    <thead class="thead-light">
                    <tr class="sorting">
                        <th><a :class="sortingClass('name')" href="#" @click="sortData('name')" >Name</a></th>
                        <th><a :class="sortingClass('status')" href="#" @click="sortData('status')" >Status</a></th>
                        <th><a :class="sortingClass('created_at')" href="#" @click="sortData('created_at')" >Created</a></th>
                        <th width="50">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in items.data" v-bind:key="item.id">
                        <td>
                            <span class="font-weight-bold">{{_.get(item,'name','-')}}</span><br>
                        </td>
                        <td>
                            <span class="badge" :class="{'badge-danger': !isCompleted(item.status), 'badge-success': isCompleted(item.status)}">
                                <i class="fa" :class="{'fa-tasks': !isCompleted(item.status), 'fa-check': isCompleted(item.status)}"></i> {{ item.status }}
                            </span>
                        </td>
                        <td>
                            <span class="font-weight-bold">{{ moment(item.created_at).format() }}</span><br>
                        </td>
                        <td class="actions">
                            <a v-if="item.is_downloadable" :href="`/catalog/downloads/download/${item.id}`" class="m-2"><i class="fas fa-cloud-download-alt"></i></a>
                            <a href="javascript:;" class="btn btn-sm btn-danger swa-confirm" @click="remove(item.id)" data-toggle="tooltip" data-original-title="Close" ><i class="far fa-trash-alt"></i></a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </filterable>
        <!-- /Row -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        data() {
            return {
                query: {
                    sort: "id",
                    sort_dir: "desc"
                },
            }
        },
        computed: {
            sortable() {
                return [
                    {value: "id", name: "Id"},
                    {value: "name", name: "Title"},
                    {value: "status", name: "Status"},
                    {value: "created_at", name: "Created"}
                ];
            },
            filters() {
                return [{
                    title: "Jobs",
                    filters: [
                        {name: "id", title: "Id"},
                        {name: "name", title: "Title", type: 'string'},
                        {name: "status", title: "Status", type: 'string'},
                        {name: "created_at", title: "Created", type: 'datetime'}
                    ]
                },
                ];
            }
        },
        methods:{
            isCompleted(status){
                return status === "Completed";
            }
        }
    }
</script>
