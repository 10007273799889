import form from '../../../utils/form';

function initialState() {
    return {
        file: null,
        query: {},
        loading: false
    }
}

const getters = {
    file:           state => state.file,
    items:          state => state.items,
    loading:        state => state.loading,
};

const actions = {
    uploadFile({ commit, state }){
        commit('setLoading', true);
        let formData = new FormData();
        formData.append('photo', state.file);
        return new Promise(function (resolve, reject) {
            axios.post( 'account/edit-picture',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(response => {
                commit('setLoading', false);
                resolve(response);
            }).catch(error => {
                commit('setLoading', false);
                commit("setErrors", _.get(error.response.data, 'errors', {}));
                reject(error);
            });
        });
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value));
    },
    resetState({ commit }) {
        commit('resetState');
    },
    setFile({ commit }, file) {
        commit('setFile', file);
    },

};

const mutations = {
    setFile(state, file) {
        state.file = file;
    },
    setItems(state, items) {
        state.items = items;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setQuery(state, query) {
        state.query = query;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};

