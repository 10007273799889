import form from "../../../utils/form";

function initialState() {
    return {
        file: null,
        item: {
            name: null,
            code: null,
            notify: false,
            notify_frequency: 30,
            notify_frequency_unit: "Minutes",
            emails: [],
            options: []
        },
        form: {
            name: null,
            code: null,
            notify: false,
            notify_frequency: null,
            notify_frequency_unit: null,
            emails: [],
            options: []
        },
        operators: [
            {'id':'equal_to', "label":"Equals TO (==)"},
            {'id':'not_equal_to', "label":"Not Equals TO (!=)"},
            {'id':'less_than', "label":"Less Than (<)"},
            {'id':'greater_than', "label":"Greater Than (>)"},
            {'id':'less_than_or_equal_to', "label":"Less Than Or Equals TO (<=)"},
            {'id':'greater_than_or_equal_to', "label":"Greater Than Or Equals TO (>=)"},
            {'id':'between', "label":"In Between"},
            {'id':'not_between', "label":"Not In Between"}
        ],
        defaultOptions: {
            "column": null,
            "operator": "equal_to",
            "query_one": null,
            "query_two": null,
            "value_one": null,
            "value_two": null
        },
        errors: {},
        loading: false,
        units: [],
    }
}

const getters = {
    item: state => state.item,
    errors: state => state.errors,
    loading: state => state.loading,
    units: state => state.units,
    operators: state => state.operators
};

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true);
        commit('setErrors', {});
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)),{indices: true});
            axios.post('catalog/vendors', params)
                .then(response => {
                    commit('resetForm');
                    resolve(response.data);
                })
                 .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    updateData({ commit, state }) {
        commit('setLoading', true);
        commit('setErrors', {});
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)), {indices: true});
            params.set('_method', 'PUT');
            axios.post(`catalog/vendors/${state.item.id}`, params)
                .then(response => {
                    resolve(response.data);
                })
                 .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    fetchData({ commit, dispatch, state }, id) {
        axios.get(`catalog/vendors/${id}`)
            .then(response => {
                commit('setItem', _.get(response.data, "data", {}));
            });
    },
    configData({ commit}) {
        axios.get(`catalog/vendors/config`)
            .then(response => {
                commit('setUnits', _.keys(_.get(response, "data.units", {})));
            });
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
    setItemProperty({ commit }, {key, value}) {
        commit('setItemProperty', {key, value})
    },
    addOption({commit, state}) {
        if(_.isEmpty(state.item.options)){
            commit('addOption');
        }
    },
    removeOption({commit, state}, value) {
        commit('removeOption', value);
    },
    setOptionProperty({commit}, {index, key, value}) {
        commit("setOptionProperty", {index, key, value});
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setErrors(state, value) {
        state.errors = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    resetForm(state) {
        state.file = null;
        _.merge(state.item, state.form);
    },
    setItemProperty(state, {key, value}){
        _.set(state.item, key, value);
    },
    setStatuses(state, value) {
        state.statuses = value
    },
    setUnits(state, units) {
        state.units = units
    },
    addOption(state) {
        state.item.options.push(Object.assign({}, state.defaultOptions));
    },
    removeOption(state, value) {
        state.item.options.splice(value, 1);
    },
    setOptionProperty(state, {index, key, value}){
        _.set(state.item.options, `${index}.${key}`, value);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};

