var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c(
        "filterable",
        {
          ref: "filterable",
          attrs: {
            endpoint: "catalog/downloads",
            params: this.query,
            sortable: _vm.sortable,
            "filter-group": _vm.filters
          },
          scopedSlots: _vm._u([
            {
              key: "filter-nav",
              fn: function(ref) {
                var sortingClass = ref.sortingClass
                var sortData = ref.sortData
                return [
                  _c("div", { staticClass: "float-right" }, [
                    _c("ul", { staticClass: "nav nav-pills reports-top-tab" }, [
                      _c(
                        "li",
                        {
                          staticClass: "ml-auto font-s-13 d-table dropdown pt-2"
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-dark d-block mt-1 dropdown-toggle",
                              attrs: {
                                href: "#",
                                "data-toggle": "dropdown",
                                role: "button",
                                "aria-expanded": "false"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Sort by\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "sorting dropdown-menu",
                              attrs: { "aria-labelledby": "navbarDropdown" }
                            },
                            _vm._l(_vm.sortable, function(s) {
                              return _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class: sortingClass(s.value),
                                  on: {
                                    click: function($event) {
                                      return sortData(s.value)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(s.name))]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("li", { staticClass: "ml-3 font-s-13 d-table pt-2" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn-filter text-dark d-block mt-1",
                            attrs: {
                              href: "#collapseFilter",
                              "data-toggle": "collapse"
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-filter" }),
                            _vm._v(" Filters")
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "ml-3 font-s-13 d-table pt-2" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "text-dark d-block mt-1",
                              attrs: {
                                to: { name: "catalog.downloads.create" }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-plus" }),
                              _vm._v(" Add new download")
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              }
            },
            {
              key: "content",
              fn: function(ref) {
                var items = ref.items
                var remove = ref.remove
                var sortingClass = ref.sortingClass
                var sortData = ref.sortData
                return [
                  _c("table", { staticClass: "table table-small mb-0" }, [
                    _c("thead", { staticClass: "thead-light" }, [
                      _c("tr", { staticClass: "sorting" }, [
                        _c("th", [
                          _c(
                            "a",
                            {
                              class: sortingClass("name"),
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return sortData("name")
                                }
                              }
                            },
                            [_vm._v("Name")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "a",
                            {
                              class: sortingClass("status"),
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return sortData("status")
                                }
                              }
                            },
                            [_vm._v("Status")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "a",
                            {
                              class: sortingClass("created_at"),
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return sortData("created_at")
                                }
                              }
                            },
                            [_vm._v("Created")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { width: "50" } }, [_vm._v("Action")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(items.data, function(item) {
                        return _c("tr", { key: item.id }, [
                          _c("td", [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(_vm._.get(item, "name", "-")))
                            ]),
                            _c("br")
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "span",
                              {
                                staticClass: "badge",
                                class: {
                                  "badge-danger": !_vm.isCompleted(item.status),
                                  "badge-success": _vm.isCompleted(item.status)
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa",
                                  class: {
                                    "fa-tasks": !_vm.isCompleted(item.status),
                                    "fa-check": _vm.isCompleted(item.status)
                                  }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(item.status) +
                                    "\n                        "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                _vm._s(_vm.moment(item.created_at).format())
                              )
                            ]),
                            _c("br")
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "actions" }, [
                            item.is_downloadable
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "m-2",
                                    attrs: {
                                      href:
                                        "/catalog/downloads/download/" + item.id
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-cloud-download-alt"
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn-sm btn-danger swa-confirm",
                                attrs: {
                                  href: "javascript:;",
                                  "data-toggle": "tooltip",
                                  "data-original-title": "Close"
                                },
                                on: {
                                  click: function($event) {
                                    return remove(item.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "far fa-trash-alt" })]
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "h6",
              { staticClass: "m-0 font-weight-bold text-primary float-left" },
              [_c("i", { staticClass: "fa fa-cogs mr-2" }), _vm._v("Downloads")]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }