<template>
    <div class="container-fluid">
        <filterable :endpoint="'catalog/orders'" :params="this.query" :sortable="sortable" :filter-group="filters" ref="filterable">
            <template slot="title">
                <h6 class="m-0 font-weight-bold text-primary float-left"><i class="fa fa-cogs mr-2"></i>Orders</h6>
            </template>
            <template slot="filter-nav" slot-scope="{ sortingClass, sortData }">
                <div class="float-right">
                    <ul class="nav nav-pills reports-top-tab">
                        <li class="ml-auto font-s-13 d-table dropdown pt-2">
                            <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="text-dark d-block mt-1 dropdown-toggle">
                                Sort by
                            </a>
                            <div aria-labelledby="navbarDropdown" class="sorting dropdown-menu">
                                <a v-for="s in sortable" class="dropdown-item" :class="sortingClass(s.value)" @click="sortData(s.value)">{{s.name}}</a>
                            </div>
                        </li>
                        <li class="ml-3 font-s-13 d-table pt-2">
                            <a href="#collapseFilter" data-toggle="collapse" class="btn-filter text-dark d-block mt-1"><i class="fa fa-filter"></i> Filters</a>
                        </li>
                    </ul>
                </div>
            </template>
            <template slot-scope="{ items, remove, sortingClass, sortData }" slot="content">
                <table class="table table-small mb-0">
                    <thead class="thead-light">
                    <tr class="sorting">
                        <th><a :class="sortingClass('order_number')" href="#" @click="sortData('order_number')" >Order</a></th>
                        <th><a :class="sortingClass('created_at')" href="#" @click="sortData('created_at')" >Date</a></th>
                        <th><a :class="sortingClass('first_name')" href="#" @click="sortData('first_name')" >Customer</a></th>
                        <th><a :class="sortingClass('financial_status')" href="#" @click="sortData('financial_status')" >Payment</a></th>
                        <th><a :class="sortingClass('fulfillment_status')" href="#" @click="sortData('fulfillment_status')" >Fulfillment</a></th>
                        <th><a :class="sortingClass('order_items_count')" href="#" >Items</a></th>
                        <th width="50">Exported</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in items.data" v-bind:key="item.id">
                        <td>
                            <span class="font-weight-bold">{{_.get(item,'order_number','-')}}</span>
                        </td>
                        <td>
                            <span v-if="item.created_at">{{ moment(item.created_at).format("LLL") }}</span>
                        </td>
                        <td>{{_.get(item,'name','-')}}</td>
                        <td><span class="badge badge-success">{{_.get(item,'financial_status','-')}}</span></td>
                        <td><span class="badge badge-success">{{_.get(item,'fulfillment_status','-')}}</span></td>
                        <td><span>{{_.get(item,'order_items_count','-')}} Items</span></td>
                        <td>
                            <span class="badge" :class="{'badge-danger': !item.exported, 'badge-success': item.exported}">
                                <i class="fa" :class="{'fa-tasks': !item.exported, 'fa-check': item.exported}"></i>
                                <template v-if="!item.exported">No</template>
                                <template v-if="item.exported">Yes</template>
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </filterable>
        <!-- /Row -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                query: {
                    sort: "id",
                    sort_dir: "desc"
                },
            }
        },
        computed: {
            sortable() {
                return [
                    {value: "id", name: "Id"},
                    {value: "order_number", name: "Order"},
                    {value: "first_name", name: "Customer"},
                    {value: "financial_status", name: "Payment"},
                    {value: "fulfillment_status", name: "Fulfillment"},
                    {value: "created_at", name: "Created"},
                    {value: "processed_at", name: "Processed At"},
                    {value: "cancelled_at", name: "Cancelled At"}
                ];
            },
            filters() {
                return [{
                    title: "Order",
                    filters: [
                        {name: "id", title: "Id"},
                        {name: "first_name", title: "Customer", type: 'string'},
                        {name: "order_number", title: "Order", type: 'string'},
                        {name: "exported", title: "Exported", type: 'toggle'},
                        {name: "confirmed", title: "Confirmed", type: 'toggle'},
                        {name: "financial_status", title: "Payment", type: 'string'},
                        {name: "fulfillment_status", title: "Fulfillment", type: 'string'},
                        {name: "created_at", title: "Created", type: 'datetime'},
                        {name: "processed_at", title: "Processed At", type: 'datetime'},
                        {name: "cancelled_at", title: "Cancelled At", type: 'datetime'}
                    ]
                },
                ];
            }
        }
    }
</script>
