import form from '../../../utils/form';

function initialState() {
    return {
        item: {
            current_password: null,
            new_password: null,
            new_password_confirmation: null
        },
        errors: {},
        loading: false,
    }
}
const getters = {
    item: state => state.item,
    errors: state => state.errors,
    loading: state => state.loading,
};

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true);
        //dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = form.formData(state.item);
            axios.post('account/change-password', params)
                .then(response => {
                    commit('resetState');
                    resolve(response.data);
                })
                .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    resetState({ commit }) {
        commit('resetState');
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
    setCurrentPassword({ commit }, value) {
        commit('setCurrentPassword', value)
    },
    setNewPassword({ commit }, value) {
        commit('setNewPassword', value)
    },
    setNewPasswordConfirmation({ commit }, value) {
        commit('setNewPasswordConfirmation', value)
    }
};

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setErrors(state, value) {
        state.errors = value
    },
    setCurrentPassword(state, value) {
        state.item.current_password = value
    },
    setNewPassword(state, value) {
        state.item.new_password = value
    },
    setNewPasswordConfirmation(state, value) {
        state.item.new_password_confirmation = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};
