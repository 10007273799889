<template>
    <div class="container-fluid">
        <!-- Row -->
        <div class="row">
            <div class="col-lg-12 col-12">
                <div class="mt-4">
                    <h4 class="">
                        Add Products
                        <back-button class="float-right"></back-button>
                    </h4>
                    <hr>
                    <div  class="">
                        <form @submit.prevent="submitForm" novalidate>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="name">Title</label>
                                        <input
                                            id="name"
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            :value="_.get(item, 'name', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'name'"></input-error>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="sku">SKU</label>
                                        <input
                                            id="sku"
                                            type="text"
                                            class="form-control"
                                            name="sku"
                                            :value="_.get(item, 'sku', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'sku'"></input-error>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="vendor">Vendor</label>
                                        <input
                                            id="vendor"
                                            type="text"
                                            class="form-control"
                                            name="vendor"
                                            :value="_.get(item, 'vendor', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'vendor'"></input-error>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="internalId">Internal ID</label>
                                        <input
                                            id="internalId"
                                            type="text"
                                            class="form-control"
                                            name="internalId"
                                            :value="_.get(item, 'internalId', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'sku'"></input-error>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="price">Price</label>
                                        <input
                                            id="price"
                                            type="text"
                                            class="form-control"
                                            name="price"
                                            :value="_.get(item, 'price', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'price'"></input-error>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="quantity">Quantity</label>
                                        <input
                                            id="quantity"
                                            type="text"
                                            class="form-control"
                                            name="quantity"
                                            :value="_.get(item, 'quantity', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'quantity'"></input-error>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="productId">Product ID</label>
                                        <input
                                            id="productId"
                                            type="text"
                                            class="form-control"
                                            name="productId"
                                            :value="_.get(item, 'productId', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'productId'"></input-error>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="variantId">Variant ID</label>
                                        <input
                                            id="variantId"
                                            type="text"
                                            class="form-control"
                                            name="variantId"
                                            :value="_.get(item, 'variantId', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'variantId'"></input-error>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="inventoryId">Inventory ID</label>
                                        <input
                                            id="inventoryId"
                                            type="text"
                                            class="form-control"
                                            name="inventoryId"
                                            :value="_.get(item, 'inventoryId', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'inventoryId'"></input-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-12">
                                    <div class="form-group text-right">
                                        <vue-button-spinner
                                                class="btn btn-sm btn-primary"
                                                :isLoading="loading"
                                                :disabled="loading"
                                        >
                                            Add
                                        </vue-button-spinner>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    import Vue from "vue";
    import { mapGetters, mapActions } from 'vuex';
    import vSelect from 'vue-select';
    import debounce from 'lodash/debounce';
    Vue.component('v-select', vSelect);
    export default {
        components: {
        },
        data() {
            return {
                xprops: {
                    module: 'ProductsForm'
                },
            }
        },
        mounted() {

        },
        computed: {
            ...mapGetters('ProductsForm', [
                'item',
                'loading',
                'errors',
            ]),
        },
        methods: {
            ...mapActions('ProductsForm', [
                'storeData',
                'setItemProperty',
                'resetErrors',
                'resetState'
            ]),
            submitForm() {
                this.storeData()
                    .then((response) => {
                        this.$awn.success('Product saved successfully.')
                    })
                    .catch((error) => {
                        this.$awn.info('OOPS! Error Occured.')
                    });
            },
            updateInput(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.value;
                this.setItemProperty({key, value});
            },
            updateCheckbox(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.checked;
                this.setItemProperty({key, value});
            },
            updateSelectValue(value, key)  {
                this.setItemProperty({key, value});
            }
        }
    }
</script>

<style scoped>

</style>
