<template>
    <header class="top-header">
        <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm fixed-top">
            <router-link v-if="!_.isEmpty(title)" class="navbar-brand" :to="{ name: 'home'}">
                {{ title }}
            </router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'home'}"><i class="fas fa-home"></i> Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'catalog.jobs'}"><i class="fa fa-list ml-3"></i> List jobs</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'catalog.jobs.create'}"><i class="fa fa-plus ml-3"></i> Create jobs</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'catalog.products'}"><i class="fa fa-list ml-3"></i> List Products</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'catalog.products.create'}"><i class="fa fa-plus ml-3"></i> Create Product</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'catalog.vendors'}"><i class="fas fa-list ml-3"></i> Vendors</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'catalog.orders'}"><i class="fas fa-list ml-3"></i> Orders</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'catalog.downloads'}"><i class="fas fa-cloud-download-alt ml-3"></i> Downloads</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'settings.general'}"><i class="fas fa-cogs ml-3"></i> Settings</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item dropdown">
                        <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="nav-link dropdown-toggle"><i class="fa fa-btn fa-picture-o"></i> {{ name }} <span class="caret"></span></a>
                        <div aria-labelledby="navbarDropdown" class="dropdown-menu dropdown-menu-right">
                            <router-link class="dropdown-item" :to="{ name: 'account'}"><i class="fa mr-1 fa-user"></i>Manage Profile</router-link>
                            <router-link class="dropdown-item" :to="{ name: 'account.changePassword'}"><i class="fa mr-1 fa-btn fa-user-secret"></i>Change password</router-link>
                            <a class="dropdown-item" :href="logoutPath" onclick="event.preventDefault();document.getElementById('logout-form').submit();"><i class="fas mr-1 fa-sign-out-alt"></i>Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
    export default {
        props: {
            title: {
                type: String | null,
                default: null
            },
            login: {
                type: Boolean,
                default: false
            },
            name: {
                type: String | null,
                default: null
            },
            logoutPath: {
                type: String | null,
                default: null
            }
        },
        data() {
            return {
                xprops: {
                    module: 'Auth'
                }
            }
        },
        computed:{
            isLoggedIn() {
                return this.login;
            }
        },
        methods:{
        }
    }
</script>

<style scoped>

</style>
