var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-2 col-12 bg-light sidebar" },
        [_c("profile-sidebar")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-10 col-12" }, [
        _c("div", { staticClass: "mt-4" }, [
          _c(
            "h4",
            { staticClass: "mb-3" },
            [
              _vm._v(
                "\n                     Edit Picture\n                    "
              ),
              _c("back-button", { staticClass: "float-right" })
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", {}, [
            _c("div", { staticClass: "large-12 medium-12 small-12 cell" }, [
              _c("form", { staticClass: "form-inline mb-2" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "pic" } }, [
                      _vm._v("Profile pic  ")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      ref: "pic",
                      attrs: { id: "pic", type: "file" },
                      on: {
                        change: function($event) {
                          return _vm.handleFileUpload()
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _vm.loading
                      ? _c("b-progress", {
                          attrs: {
                            value: 100,
                            variant: "success",
                            "show-progress": "",
                            animated: ""
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("hr")
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }