<template>
    <div class="settings-sidebar">
        <div class="mt-3">
            <h4><i class="fa fa-cogs"></i> Configuration</h4>
            <hr>
            <ul class="nav nav-pills flex-column">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'settings.general' }">General</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'settings.mailbox' }">Email CSV import</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped>

</style>
