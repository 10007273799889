var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-2 col-12 bg-white" },
        [_c("settings-sidebar")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-10 col-12" }, [
        _c("div", { staticClass: "mt-3" }, [
          _c(
            "h4",
            { staticClass: "mb-3" },
            [
              _vm._v(
                "\n                 MailBox Settings\n                    "
              ),
              _c("back-button", { staticClass: "float-right" })
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", {}, [
            _c("div", {}, [
              _c(
                "form",
                {
                  attrs: { novalidate: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-check form-group" },
                        [
                          _c("input", {
                            staticClass: "form-check-input",
                            attrs: {
                              id: "enable-email-import",
                              type: "checkbox",
                              name: "enable-email-import",
                              "true-value": 1,
                              "false-value": 0,
                              required: ""
                            },
                            domProps: { checked: _vm.downloadEnabled },
                            on: { input: _vm.updateCheckbox }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label mb-10",
                              attrs: { for: "enable-email-import" }
                            },
                            [
                              _vm._v(
                                "Automatically download csv from mailbox and process?"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("small", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              " If checked csv will be downloaded every " +
                                _vm._s(
                                  _vm._.get(_vm.item, "download-frequency", 2)
                                ) +
                                " Hours"
                            )
                          ]),
                          _vm._v(" "),
                          _c("input-error", {
                            attrs: {
                              errors: _vm.errors,
                              name: "download-orders"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.downloadEnabled
                      ? _c("div", { staticClass: "col-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group required" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label mb-10",
                                  attrs: { for: "download-frequency" }
                                },
                                [_vm._v("Download Frequency")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "download-frequency",
                                  type: "number",
                                  name: "download-frequency",
                                  required: ""
                                },
                                domProps: {
                                  value: _vm._.get(
                                    _vm.item,
                                    "download-frequency",
                                    2
                                  )
                                },
                                on: { input: _vm.updateInput }
                              }),
                              _vm._v(" "),
                              _c("input-error", {
                                attrs: {
                                  errors: _vm.errors,
                                  name: "download-frequency"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.downloadEnabled
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group required" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label mb-10",
                                    attrs: { for: "hostname" }
                                  },
                                  [_vm._v("Hostname")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "hostname",
                                    type: "text",
                                    name: "hostname",
                                    required: ""
                                  },
                                  domProps: {
                                    value: _vm._.get(_vm.item, "hostname", null)
                                  },
                                  on: { input: _vm.updateInput }
                                }),
                                _vm._v(" "),
                                _c("input-error", {
                                  attrs: {
                                    errors: _vm.errors,
                                    name: "hostname"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group required" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label mb-10",
                                    attrs: { for: "port" }
                                  },
                                  [_vm._v("Port")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "port",
                                    type: "number",
                                    name: "port",
                                    required: ""
                                  },
                                  domProps: {
                                    value: _vm._.get(_vm.item, "port", null)
                                  },
                                  on: { input: _vm.updateInput }
                                }),
                                _vm._v(" "),
                                _c("input-error", {
                                  attrs: {
                                    errors: _vm.errors,
                                    name: "hostname"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group required" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label mb-10",
                                    attrs: { for: "username" }
                                  },
                                  [_vm._v("Username")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "username",
                                    type: "text",
                                    name: "username",
                                    required: ""
                                  },
                                  domProps: {
                                    value: _vm._.get(_vm.item, "username", null)
                                  },
                                  on: { input: _vm.updateInput }
                                }),
                                _vm._v(" "),
                                _c("input-error", {
                                  attrs: {
                                    errors: _vm.errors,
                                    name: "username"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group required" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label mb-10",
                                    attrs: { for: "password" }
                                  },
                                  [_vm._v("Password")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "password",
                                    type: "text",
                                    name: "password",
                                    required: ""
                                  },
                                  domProps: {
                                    value: _vm._.get(_vm.item, "password", null)
                                  },
                                  on: { input: _vm.updateInput }
                                }),
                                _vm._v(" "),
                                _c("input-error", {
                                  attrs: {
                                    errors: _vm.errors,
                                    name: "password"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-check form-group" },
                        [
                          _c("input", {
                            staticClass: "form-check-input",
                            attrs: {
                              id: "update-price",
                              type: "checkbox",
                              name: "update-price",
                              "true-value": 1,
                              "false-value": 0,
                              required: ""
                            },
                            domProps: { checked: _vm.isActive("update-price") },
                            on: { input: _vm.updateCheckbox }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label mb-10",
                              attrs: { for: "update-price" }
                            },
                            [_vm._v("Automatically update price from CSV?")]
                          ),
                          _vm._v(" "),
                          _c("input-error", {
                            attrs: { errors: _vm.errors, name: "update-price" }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.isActive("update-price")
                      ? _c("div", { staticClass: "col-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group required" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label mb-10",
                                  attrs: { for: "update-price-frequency" }
                                },
                                [_vm._v("Price Update Frequency")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "update-price-frequency",
                                  type: "number",
                                  name: "update-price-frequency",
                                  required: ""
                                },
                                domProps: {
                                  value: _vm._.get(
                                    _vm.item,
                                    "update-price-frequency",
                                    2
                                  )
                                },
                                on: { input: _vm.updateInput }
                              }),
                              _vm._v(" "),
                              _c("input-error", {
                                attrs: {
                                  errors: _vm.errors,
                                  name: "update-price-frequency"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isActive("update-price")
                      ? _c("div", { staticClass: "col-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group required" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label mb-10",
                                  attrs: { for: "base-price" }
                                },
                                [_vm._v("Base Price Multiplier")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "base-price",
                                  type: "text",
                                  name: "base-price",
                                  required: _vm.isActive("update-price")
                                },
                                domProps: {
                                  value: _vm._.get(_vm.item, "base-price", null)
                                },
                                on: { input: _vm.updateInput }
                              }),
                              _vm._v(" "),
                              _c("input-error", {
                                attrs: {
                                  errors: _vm.errors,
                                  name: "base-price"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isActive("update-price")
                      ? _c("div", { staticClass: "col-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group required" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label mb-10",
                                  attrs: { for: "compare-price" }
                                },
                                [_vm._v("Compare Price Multiplier")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "compare-price",
                                  type: "text",
                                  name: "compare-price",
                                  required: _vm.isActive("update-price")
                                },
                                domProps: {
                                  value: _vm._.get(
                                    _vm.item,
                                    "compare-price",
                                    null
                                  )
                                },
                                on: { input: _vm.updateInput }
                              }),
                              _vm._v(" "),
                              _c("input-error", {
                                attrs: {
                                  errors: _vm.errors,
                                  name: "compare-price"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isActive("update-price")
                      ? _c("div", { staticClass: "col-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-check form-check-inline" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label mb-10",
                                  attrs: { for: "manage-fraction" }
                                },
                                [
                                  _c("input", {
                                    staticClass: "form-check-input",
                                    attrs: {
                                      id: "manage-fraction",
                                      type: "checkbox",
                                      name: "manage-fraction"
                                    },
                                    domProps: {
                                      checked: _vm.isActive("manage-fraction")
                                    },
                                    on: { input: _vm.updateCheckbox }
                                  }),
                                  _vm._v(
                                    "\n                                        Manage Price Fraction\n                                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("input-error", {
                                attrs: {
                                  errors: _vm.errors,
                                  name: "manage-fraction"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isActive("manage-fraction") &&
                    _vm.isActive("update-price")
                      ? _c("div", { staticClass: "col-5" }, [
                          _c(
                            "div",
                            { staticClass: "form-group required" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label mb-10",
                                  attrs: { for: "fraction-value" }
                                },
                                [_vm._v("Fraction value to manage")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "fraction-value",
                                  type: "text",
                                  name: "fraction-value",
                                  required:
                                    _vm.isActive("manage-fraction") &&
                                    _vm.isActive("update-price")
                                },
                                domProps: {
                                  value: _vm._.get(
                                    _vm.item,
                                    "fraction-value",
                                    null
                                  )
                                },
                                on: { input: _vm.updateInput }
                              }),
                              _vm._v(" "),
                              _c("input-error", {
                                attrs: {
                                  errors: _vm.errors,
                                  name: "fraction-value"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isActive("manage-fraction") &&
                    _vm.isActive("update-price")
                      ? _c("div", { staticClass: "col-5" }, [
                          _c(
                            "div",
                            { staticClass: "form-group required" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label mb-10",
                                  attrs: { for: "fraction-multiplier" }
                                },
                                [_vm._v("Fraction value multiplier")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "fraction-multiplier",
                                  type: "text",
                                  name: "fraction-multiplier",
                                  required:
                                    _vm.isActive("manage-fraction") &&
                                    _vm.isActive("update-price")
                                },
                                domProps: {
                                  value: _vm._.get(
                                    _vm.item,
                                    "fraction-multiplier",
                                    null
                                  )
                                },
                                on: { input: _vm.updateInput }
                              }),
                              _vm._v(" "),
                              _c("input-error", {
                                attrs: {
                                  errors: _vm.errors,
                                  name: "fraction-multiplier"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-12 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group text-right" },
                        [
                          _c(
                            "vue-button-spinner",
                            {
                              staticClass: "btn btn-sm btn-primary",
                              attrs: {
                                isLoading: _vm.loading,
                                disabled: _vm.loading
                              }
                            },
                            [
                              _vm._v(
                                "\n                                        Update\n                                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }