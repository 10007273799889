require('./bootstrap');
require("./filters");
import Vue from "vue";
import store from "./store";
import router from "./routes";
import BarPlugin from './components/Common/loading-bar';
import config from "./config/core";

Vue.component('back-button', require('./components/Common/BackButton.vue').default);
Vue.component('back-link', require('./components/Common/BackLink.vue').default);
Vue.component('event-hub', require('./components/Common/EventHub.vue').default);
Vue.component('vue-button-spinner', require('./components/Common/VueButtonSpinner.vue').default);
Vue.component('input-error', require('./components/Common/InputError.vue').default);
Vue.component('filterable', require('./components/Common/Filterable.vue').default);
Vue.component('top-nav', require('./components/Common/TopNav.vue').default);
Vue.component('sidebar', require('./components/Common/Sidebar.vue').default);
Vue.component('profile-sidebar', require('./components/Common/ProfileSidebar.vue').default);
Vue.component('settings-sidebar', require('./components/Common/SettingsSidebar.vue').default);
Vue.component('loader-bar', require('./components/Common/Loader.vue').default);
Vue.component('tooltip', require('./components/Common/Tooltip.vue').default);
Vue.component('x-tag-input', require('./components/Common/TagInput.vue').default);
Vue.component('x-switch', require('./components/Common/Switch.vue').default);

window.onload = function () {
    Vue.use(BarPlugin);
    router.beforeResolve((to, from, next) => {
        // If this isn't an initial page load.
        BarPlugin.bar.start();
        next()
    });

    router.afterEach((to, from) => {
        // Complete the animation of the route progress bar.
        BarPlugin.bar.finish();
    });
    const app = new Vue({
        data: {
            dpconfigDate: {
                format: window.date_format_moment
            },
            dpconfigTime: {
                format: window.time_format_moment
            },
            dpconfigDatetime: {
                format: window.datetime_format_moment,
                sideBySide: true
            }
        },
        mounted() {
            this.updateRules();

        },
        watch: {
            '$route': function() {
                this.updateRules();
            }
        },
        methods: {
            updateRules() {
            }
        },
        router: router,
        store: store
    }).$mount('#app');
};
