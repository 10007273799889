var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-2 col-12 bg-light sidebar" },
        [_c("profile-sidebar")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-10 col-12" }, [
        _c("div", { staticClass: "mt-4" }, [
          _c(
            "h4",
            { staticClass: "mb-3" },
            [
              _vm._v(
                "\n                     Change Password\n                    "
              ),
              _c("back-button", { staticClass: "float-right" })
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "row justify-content-md-center" }, [
            _c("div", { staticClass: "col-md-6 mt-4" }, [
              _c(
                "form",
                {
                  attrs: { novalidate: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label mb-10",
                          attrs: { for: "current_password" }
                        },
                        [_vm._v("Current Password")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          id: "current_password",
                          type: "password",
                          name: "current_password",
                          required: ""
                        },
                        domProps: { value: _vm.item.current_password },
                        on: { input: _vm.updateCurrentPassword }
                      }),
                      _vm._v(" "),
                      _c("input-error", {
                        attrs: { errors: _vm.errors, name: "current_password" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label mb-10",
                          attrs: { for: "new_password" }
                        },
                        [_vm._v("New Password")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          id: "new_password",
                          type: "password",
                          name: "new_password",
                          required: ""
                        },
                        domProps: { value: _vm.item.new_password },
                        on: { input: _vm.updateNewPassword }
                      }),
                      _vm._v(" "),
                      _c("input-error", {
                        attrs: { errors: _vm.errors, name: "new_password" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label mb-10",
                          attrs: { for: "password-confirm" }
                        },
                        [_vm._v("Confirm Password")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          id: "password-confirm",
                          type: "password",
                          name: "new_password_confirmation",
                          required: ""
                        },
                        domProps: { value: _vm.item.new_password_confirmation },
                        on: { input: _vm.updateNewPasswordConfirmation }
                      }),
                      _vm._v(" "),
                      _c("input-error", {
                        attrs: {
                          errors: _vm.errors,
                          name: "new_password_confirmation"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group text-right" },
                    [
                      _c(
                        "vue-button-spinner",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: {
                            isLoading: _vm.loading,
                            disabled: _vm.loading
                          }
                        },
                        [
                          _vm._v(
                            "\n                                        Update Password\n                                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }