function initialState() {
    return {
        endpoint: null,
        exportEndpoint: null,
        errors: {},
        items: {},
        loading: false,
        query: {}
    }
}

const getters = {
    errors:         state => state.errors,
    items:          state => state.items,
    loading:        state => state.loading,
    query:          state => state.query,
    meta:           state => _.get(state.items, "meta", {})
};

const actions = {
    fetchData({ commit, state }) {
        if(_.isNull(state.endpoint) || _.isEmpty(state.endpoint)) {
            return null;
        }
        commit('setLoading', true);
        axios.get(`${state.endpoint}`, {
            params: state.query
        })
            .then(response => {
                commit('setItems', _.get(response, "data", []));
            })
            .catch(error => {
                let message = error.message;
                commit("setErrors", _.get(error.response.data, 'errors', {}));
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    exportData({ commit, state }) {
        if(_.isNull(state.exportEndpoint) || _.isEmpty(state.exportEndpoint)) {
            return null;
        }
        commit('setLoading', true);
        axios.get(`${state.exportEndpoint}`, {
            params: state.query
        })
            .then(response => {

            })
            .catch(error => {
                let message = error.message;
                commit("setErrors", _.get(error.response.data, 'errors', {}));
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    destroyData({ commit, state, dispatch }, id) {
        return new Promise((resolve, reject) => {
            if(_.isNull(state.endpoint) || _.isEmpty(state.endpoint)) {
                return reject({message: "Unable to delete record."});
            }
            axios.delete(`${state.endpoint}/${id}`)
                .then(response => {
                    dispatch("fetchData")
                    resolve(response);
                })
                .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                });
        });
    },
    setQuery({ commit }, value) {
        commit('setQuery', purify(value));
    },
    setEndpoint({ commit }, value) {
        commit('setEndpoint', value);
    },
    setExportEndpoint({ commit }, value) {
        commit('setExportEndpoint', value);
    },
    resetState({ commit }) {
        commit('resetState');
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
};

const mutations = {
    setItems(state, items) {
        state.items = items;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setQuery(state, query) {
        state.query = query;
    },
    setEndpoint(state, value) {
        state.endpoint = value;
    },
    setExportEndpoint(state, value) {
        state.exportEndpoint = value;
    },
    setErrors(state, value) {
        state.errors = value
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};
