<template>
    <div class="container-fluid">

        <!-- Row -->
        <div class="row">
            <div class="col-lg-12 col-12">
                <div class="mt-4">
                    <h4 class="">
                        Edit Page
                        <back-button class="float-right"></back-button>
                    </h4>

                    <hr>
                    <div  class="">
                        <form @submit.prevent="submitForm" novalidate>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="name">Title</label>
                                        <input
                                            id="name"
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            :value="_.get(item, 'name', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'name'"></input-error>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="status">Status</label>
                                        <v-select :value="item.status" @input="updateSelectValue($event,'status')" label="text" id="status" :filterable="false" :options="statuses" placeholder="Select status">
                                            <template slot="no-options">
                                                type to search status..
                                            </template>
                                        </v-select>
                                        <input-error :errors="errors" :name="'status'"></input-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <div class="form-check form-check-inline">
                                        <label class="control-label mb-10" for="update_price">
                                            <input
                                                id="update_price"
                                                type="checkbox"
                                                class="form-check-input"
                                                name="update_price"
                                                :checked="_.get(item, 'update_price', false)"
                                                @input="updateCheckbox"
                                            >
                                            Update Price
                                        </label>
                                        <input-error :errors="errors" :name="'update_price'"></input-error>
                                    </div>
                                </div>
                                <div class="col-5" v-if="_.get(item, 'update_price', false)">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="base_price">Base Price Multiplier</label>
                                        <input
                                            id="base_price"
                                            type="text"
                                            class="form-control"
                                            name="base_price"
                                            :value="_.get(item, 'base_price', null)"
                                            @input="updateInput"
                                            :required="_.get(item, 'update_price', false)"
                                        >
                                        <input-error :errors="errors" :name="'base_price'"></input-error>
                                    </div>
                                </div>
                                <div class="col-5" v-if="_.get(item, 'update_price', false)">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="compare_price">Compare Price Multiplier</label>
                                        <input
                                            id="compare_price"
                                            type="text"
                                            class="form-control"
                                            name="compare_price"
                                            :value="_.get(item, 'compare_price', null)"
                                            @input="updateInput"
                                            :required="_.get(item, 'update_price', false)"
                                        >
                                        <input-error :errors="errors" :name="'compare_price'"></input-error>
                                    </div>
                                </div>
                                <div class="col-12"  v-if="_.get(item, 'update_price', false)">
                                    <h5>Conditions</h5><hr>
                                    <div class="row">
                                        <template v-for="(option, optionIndex) in item.options">
                                            <div class="col-3">
                                                <v-select :value="option.operator" @input="updateOperator($event,'operator', optionIndex)" label="label" :id="`operator_${optionIndex}`" :filterable="false" :options="operators" placeholder="Select operator">
                                                    <template slot="no-options">
                                                        Select Operator
                                                    </template>
                                                </v-select>
                                                <input-error :errors="errors" :name="`${optionIndex}.operator`"></input-error>
                                            </div>
                                            <div :class="{'col-2': _.includes(['between', 'not_between'], option.operator), 'col-4': !_.includes(['between', 'not_between'], option.operator)}">
                                                <input
                                                    :id="`query_one_${optionIndex}`"
                                                    type="text"
                                                    class="form-control"
                                                    name="query_one"
                                                    :input-index="optionIndex"
                                                    :value="_.get(option, 'query_one', null)"
                                                    @input="updateOptionInput"
                                                    placeholder="Comparison Value First"
                                                    required
                                                >
                                                <input-error :errors="errors" :name="`${optionIndex}.query_one`"></input-error>
                                            </div>
                                            <div class="col-2" v-if="_.includes(['between', 'not_between'], option.operator)">
                                                <input
                                                    :id="`query_two_${optionIndex}`"
                                                    type="text"
                                                    class="form-control"
                                                    name="query_two"
                                                    :input-index="optionIndex"
                                                    :value="_.get(option, 'query_two', null)"
                                                    @input="updateOptionInput"
                                                    placeholder="Comparison Value Second"
                                                    required
                                                >
                                                <input-error :errors="errors" :name="`${optionIndex}.query_two`"></input-error>
                                            </div>
                                            <div class="col-2">
                                                <input
                                                    :id="`base_price_${optionIndex}`"
                                                    type="text"
                                                    class="form-control"
                                                    name="base_price"
                                                    :input-index="optionIndex"
                                                    :value="_.get(option, 'base_price', null)"
                                                    @input="updateOptionInput"
                                                    placeholder="Base Price"
                                                    required
                                                >
                                                <input-error :errors="errors" :name="`${optionIndex}.base_price`"></input-error>
                                            </div>
                                            <div class="col-2">
                                                <input
                                                    :id="`compare_price_${optionIndex}`"
                                                    type="text"
                                                    class="form-control"
                                                    name="compare_price"
                                                    :input-index="optionIndex"
                                                    :value="_.get(option, 'compare_price', null)"
                                                    @input="updateOptionInput"
                                                    placeholder="Compare Price"
                                                    required
                                                >
                                                <input-error :errors="errors" :name="`${optionIndex}.compare_price`"></input-error>
                                            </div>
                                            <div class="col-1">
                                                <a class="btn btn-sm btn-danger float-right" @click="removeOption(optionIndex)">X</a>
                                            </div>

                                        </template>
                                        <div class="col-12">
                                            <a class="btn btn-sm btn-success float-right" @click="addOption()"><i class="fa fa-plus mr-2"></i>Add New</a>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <div class="form-check form-check-inline">
                                        <label class="control-label mb-10" for="manage_fraction">
                                            <input
                                                id="manage_fraction"
                                                type="checkbox"
                                                class="form-check-input"
                                                name="manage_fraction"
                                                :checked="_.get(item, 'manage_fraction', false)"
                                                @input="updateCheckbox"
                                            >
                                            Manage Price Fraction
                                        </label>
                                        <input-error :errors="errors" :name="'manage_fraction'"></input-error>
                                    </div>
                                </div>
                                <div class="col-5" v-if="_.get(item, 'manage_fraction', false)">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="fraction_value">Fraction value to manage</label>
                                        <input
                                            id="fraction_value"
                                            type="text"
                                            class="form-control"
                                            name="fraction_value"
                                            :value="_.get(item, 'fraction_value', null)"
                                            @input="updateInput"
                                            :required="_.get(item, 'manage_fraction', false)"
                                        >
                                        <input-error :errors="errors" :name="'fraction_value'"></input-error>
                                    </div>
                                </div>
                                <div class="col-5" v-if="_.get(item, 'manage_fraction', false)">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="fraction_multiplier">Fraction value multiplier</label>
                                        <input
                                            id="fraction_multiplier"
                                            type="text"
                                            class="form-control"
                                            name="fraction_multiplier"
                                            :value="_.get(item, 'fraction_multiplier', null)"
                                            @input="updateInput"
                                            :required="_.get(item, 'manage_fraction', false)"
                                        >
                                        <input-error :errors="errors" :name="'fraction_multiplier'"></input-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <div class="form-check form-check-inline">
                                        <label class="control-label mb-10" for="create_product">
                                            <input
                                                id="create_product"
                                                type="checkbox"
                                                class="form-check-input"
                                                name="create_product"
                                                :checked="_.get(item, 'create_product', false)"
                                                @input="updateCheckbox"
                                            >
                                            Create Missing Product
                                        </label>
                                        <input-error :errors="errors" :name="'create_product'"></input-error>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-check form-check-inline">
                                        <label class="control-label mb-10" for="update_internalId">
                                            <input
                                                id="update_internalId"
                                                type="checkbox"
                                                class="form-check-input"
                                                name="update_internalId"
                                                :checked="_.get(item, 'update_internalId', false)"
                                                @input="updateCheckbox"
                                            >
                                            Update Internal ID
                                        </label>
                                        <input-error :errors="errors" :name="'update_internalId'"></input-error>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12">
                                    <div class="form-group text-right">
                                        <vue-button-spinner
                                            class="btn btn-sm btn-primary"
                                            :isLoading="loading"
                                            :disabled="loading"
                                        >
                                            Update
                                        </vue-button-spinner>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    import Vue from "vue";
    import { mapGetters, mapActions } from 'vuex';
    import vSelect from 'vue-select';
    import debounce from 'lodash/debounce';
    Vue.component('v-select', vSelect);
    export default {
        components: {
        },
        data() {
            return {
                xprops: {
                    module: 'CmsPagesForm'
                }
            }
        },
        created() {
            this.fetchData(this.$route.params.id);
        },
        destroyed() {
            this.resetState();
        },
        watch: {
            "$route.params.id": function() {
                this.resetState();
                this.fetchData(this.$route.params.id)
            }
        },
        computed: {
            ...mapGetters('JobsForm', [
                'item',
                'loading',
                'statuses',
                'operators',
                'errors'
            ])
        },
        methods: {
            ...mapActions('JobsForm', [
                'fetchData',
                'updateData',
                'setItemProperty',
                'setFile',
                'resetErrors',
                'resetState',
                'addOption',
                'removeOption',
                'setOptionProperty'
            ]),
            submitForm() {
                this.updateData()
                    .then((response) => {
                        this.$awn.success('Job saved successfully.')
                    })
                    .catch((error) => {
                        this.$awn.info('OOPS! Error Occured.')
                    });
            },
            updateInput(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.value;
                this.setItemProperty({key, value});
            },
            updateOptionInput(e) {
                let key = e.target.getAttribute("name");
                let index = e.target.getAttribute("input-index");
                let value = e.target.value;
                this.setOptionProperty({index, key, value});
            },
            updateOperator(value, key, index)  {
                if(_.isObject(value)){
                    value = value.id;
                    this.setOptionProperty({index, key, value});
                }else{
                    this.setOptionProperty({index, key, value});
                }
            },
            updateSelectValue(value, key)  {
                this.setItemProperty({key, value});
            },
            updateCheckbox(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.checked;
                this.setItemProperty({key, value});
            },
            handleFileUpload(){
                const file = this.$refs.path.files[0];
                this.setFile(file);
            },
        }
    }
</script>

<style scoped>

</style>
