<template>
    <div class="container-fluid">
        <!-- Row -->
        <div class="row">
            <div class="col-lg-2 col-12 bg-light sidebar">
               <profile-sidebar/>
            </div>
            <div class="col-lg-10 col-12">
                <div class="mt-4">
                    <h4  class="mb-3">
                         Change Password
                        <back-button class="float-right"></back-button>
                    </h4>
                    <hr>
                    <div class="row justify-content-md-center">
                        <div class="col-md-6 mt-4">
                            <form @submit.prevent="submitForm" novalidate>
                                    <div class="form-group">
                                        <label class="control-label mb-10" for="current_password">Current Password</label>
                                        <input
                                                id="current_password"
                                                type="password"
                                                class="form-control"
                                                name="current_password"
                                                :value="item.current_password"
                                                @input="updateCurrentPassword"
                                                required
                                        >
                                        <input-error :errors="errors" :name="'current_password'"></input-error>
                                    </div>
                                    <div class="form-group">
                                        <label class="control-label mb-10" for="new_password">New Password</label>
                                        <input
                                                id="new_password"
                                                type="password"
                                                class="form-control"
                                                name="new_password"
                                                :value="item.new_password"
                                                @input="updateNewPassword"
                                                required
                                        >
                                        <input-error :errors="errors" :name="'new_password'"></input-error>
                                    </div>
                                    <div class="form-group">
                                        <label for="password-confirm" class="control-label mb-10">Confirm Password</label>
                                        <input
                                                id="password-confirm"
                                                type="password"
                                                class="form-control"
                                                name="new_password_confirmation"
                                                :value="item.new_password_confirmation"
                                                @input="updateNewPasswordConfirmation"
                                                required
                                        >
                                        <input-error :errors="errors" :name="'new_password_confirmation'"></input-error>
                                    </div>
                                    <div class="form-group text-right">
                                        <vue-button-spinner
                                                class="btn btn-primary btn-sm"
                                                :isLoading="loading"
                                                :disabled="loading"
                                        >
                                            Update Password
                                        </vue-button-spinner>
                                    </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        data() {
            return {
                xprops: {
                    module: 'AccountsChangePassword'
                }
            }
        },
        mounted() {
        },
        created() {
        },
        destroyed() {
        },
        computed: {
            ...mapGetters('AccountsChangePassword', ['item', 'loading', 'errors']),
        },
        methods: {
            ...mapActions('AccountsChangePassword', ['storeData', 'setCurrentPassword', 'setNewPassword', 'setNewPasswordConfirmation', 'resetErrors']),
            submitForm() {
                //this.resetErrors();
                this.storeData()
                    .then((response) => {
                        this.$awn.success('Your account password successfully changed.')
                    })
                    .catch((error) => {
                        this.$awn.info('OOPS! Error Occured.')
                    });
            },
            updateCurrentPassword(e) {
                this.setCurrentPassword(e.target.value);
            },
            updateNewPassword(e) {
                this.setNewPassword(e.target.value);
            },
            updateNewPasswordConfirmation(e) {
                this.setNewPasswordConfirmation(e.target.value);
            }
        }
    }
</script>

<style scoped>

</style>
