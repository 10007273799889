<template>
    <div class="container-fluid">
        <!-- Row -->
        <div class="row">
            <div class="col-lg-12 col-12">
                <div class="mt-4">
                    <h4 class="">
                        Add Job
                        <back-button class="float-right"></back-button>
                    </h4>
                    <hr>
                    <div  class="">
                        <form @submit.prevent="submitForm" novalidate>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="name">Title</label>
                                        <input
                                            id="name"
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            :value="_.get(item, 'name', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'name'"></input-error>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label mb-10" >Status</label><br>
                                        <strong class="badge badge-info text-white">Pending</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-12">
                                    <div class="form-group text-right">
                                        <vue-button-spinner
                                                class="btn btn-sm btn-primary"
                                                :isLoading="loading"
                                                :disabled="loading"
                                        >
                                            Add
                                        </vue-button-spinner>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        components: {
        },
        data() {
            return {
                xprops: {
                    module: 'DownloadsForm'
                },
            }
        },
        mounted() {

        },
        computed: {
            ...mapGetters('DownloadsForm', [
                'item',
                'loading',
                'statuses',
                'errors'
            ]),
        },
        methods: {
            ...mapActions('DownloadsForm', [
                'storeData',
                'setItemProperty',
                'resetErrors',
                'resetState'
            ]),
            submitForm() {
                this.storeData()
                    .then((response) => {
                        this.$awn.success('Download queued successfully.')
                    })
                    .catch((error) => {
                        this.$awn.info('OOPS! Error Occured.')
                    });
            },
            updateInput(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.value;
                this.setItemProperty({key, value});
            },
            updateCheckbox(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.checked;
                this.setItemProperty({key, value});
            },
        }
    }
</script>

<style scoped>

</style>
