<template>
    <div class="container-fluid">
        <!-- Row -->
        <div class="row">
            <div class="col-lg-2 col-12 bg-white">
               <settings-sidebar/>
            </div>
            <div class="col-lg-10 col-12">
                <div class="mt-3">
                    <h4  class="mb-3">
                     MailBox Settings
                        <back-button class="float-right"></back-button>
                    </h4>
                    <hr>
                    <div  class="">
                        <div  class="">
                        <form @submit.prevent="submitForm" novalidate>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-check form-group">
                                        <input
                                            id="enable-email-import"
                                            type="checkbox"
                                            class="form-check-input"
                                            name="enable-email-import"
                                            :true-value="1"
                                            :false-value="0"
                                            :checked="downloadEnabled"
                                            @input="updateCheckbox"
                                            required
                                        >
                                        <label class="form-check-label mb-10" for="enable-email-import">Automatically download csv from mailbox and process?</label>
                                        <small class="form-text text-muted"> If checked csv will be downloaded every {{_.get(item, 'download-frequency', 2)}} Hours</small>
                                        <input-error :errors="errors" :name="'download-orders'"></input-error>
                                    </div>
                                </div>
                                <div class="col-6" v-if="downloadEnabled">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="download-frequency">Download Frequency</label>
                                        <input
                                            id="download-frequency"
                                            type="number"
                                            class="form-control"
                                            name="download-frequency"
                                            :value="_.get(item, 'download-frequency', 2)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'download-frequency'"></input-error>
                                    </div>
                                </div>
                                <div class="row" v-if="downloadEnabled">
                                    <div class="col-6">
                                        <div class="form-group required">
                                            <label class="control-label mb-10" for="hostname">Hostname</label>
                                            <input
                                                id="hostname"
                                                type="text"
                                                class="form-control"
                                                name="hostname"
                                                :value="_.get(item, 'hostname', null)"
                                                @input="updateInput"
                                                required
                                            >
                                            <input-error :errors="errors" :name="'hostname'"></input-error>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group required">
                                            <label class="control-label mb-10" for="port">Port</label>
                                            <input
                                                id="port"
                                                type="number"
                                                class="form-control"
                                                name="port"
                                                :value="_.get(item, 'port', null)"
                                                @input="updateInput"
                                                required
                                            >
                                            <input-error :errors="errors" :name="'hostname'"></input-error>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group required">
                                            <label class="control-label mb-10" for="username">Username</label>
                                            <input
                                                id="username"
                                                type="text"
                                                class="form-control"
                                                name="username"
                                                :value="_.get(item, 'username', null)"
                                                @input="updateInput"
                                                required
                                            >
                                            <input-error :errors="errors" :name="'username'"></input-error>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group required">
                                            <label class="control-label mb-10" for="password">Password</label>
                                            <input
                                                id="password"
                                                type="text"
                                                class="form-control"
                                                name="password"
                                                :value="_.get(item, 'password', null)"
                                                @input="updateInput"
                                                required
                                            >
                                            <input-error :errors="errors" :name="'password'"></input-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-check form-group">
                                        <input
                                            id="update-price"
                                            type="checkbox"
                                            class="form-check-input"
                                            name="update-price"
                                            :true-value="1"
                                            :false-value="0"
                                            :checked="isActive('update-price')"
                                            @input="updateCheckbox"
                                            required
                                        >
                                        <label class="form-check-label mb-10" for='update-price'>Automatically update price from CSV?</label>
                                        <input-error :errors="errors" :name="'update-price'"></input-error>
                                    </div>
                                </div>
                                <div class="col-6" v-if="isActive('update-price')">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="update-price-frequency">Price Update Frequency</label>
                                        <input
                                            id="update-price-frequency"
                                            type="number"
                                            class="form-control"
                                            name="update-price-frequency"
                                            :value="_.get(item, 'update-price-frequency', 2)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'update-price-frequency'"></input-error>
                                    </div>
                                </div>
                                <div class="col-6" v-if="isActive('update-price')">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="base-price">Base Price Multiplier</label>
                                        <input
                                            id="base-price"
                                            type="text"
                                            class="form-control"
                                            name="base-price"
                                            :value="_.get(item, 'base-price', null)"
                                            @input="updateInput"
                                            :required="isActive('update-price')"
                                        >
                                        <input-error :errors="errors" :name="'base-price'"></input-error>
                                    </div>
                                </div>
                                <div class="col-6" v-if="isActive('update-price')">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="compare-price">Compare Price Multiplier</label>
                                        <input
                                            id="compare-price"
                                            type="text"
                                            class="form-control"
                                            name="compare-price"
                                            :value="_.get(item, 'compare-price', null)"
                                            @input="updateInput"
                                            :required="isActive('update-price')"
                                        >
                                        <input-error :errors="errors" :name="'compare-price'"></input-error>
                                    </div>
                                </div>
                                <div class="col-2" v-if="isActive('update-price')">
                                    <div class="form-check form-check-inline">
                                        <label class="control-label mb-10" for="manage-fraction">
                                            <input
                                                id="manage-fraction"
                                                type="checkbox"
                                                class="form-check-input"
                                                name="manage-fraction"
                                                :checked="isActive('manage-fraction')"
                                                @input="updateCheckbox"
                                            >
                                            Manage Price Fraction
                                        </label>
                                        <input-error :errors="errors" :name="'manage-fraction'"></input-error>
                                    </div>
                                </div>
                                <div class="col-5" v-if="isActive('manage-fraction') && isActive('update-price')">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="fraction-value">Fraction value to manage</label>
                                        <input
                                            id="fraction-value"
                                            type="text"
                                            class="form-control"
                                            name="fraction-value"
                                            :value="_.get(item, 'fraction-value', null)"
                                            @input="updateInput"
                                            :required="isActive('manage-fraction') && isActive('update-price')"
                                        >
                                        <input-error :errors="errors" :name="'fraction-value'"></input-error>
                                    </div>
                                </div>
                                <div class="col-5" v-if="isActive('manage-fraction') && isActive('update-price')">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="fraction-multiplier">Fraction value multiplier</label>
                                        <input
                                            id="fraction-multiplier"
                                            type="text"
                                            class="form-control"
                                            name="fraction-multiplier"
                                            :value="_.get(item, 'fraction-multiplier', null)"
                                            @input="updateInput"
                                            :required="isActive('manage-fraction') && isActive('update-price')"
                                        >
                                        <input-error :errors="errors" :name="'fraction-multiplier'"></input-error>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12">
                                    <div class="form-group text-right">
                                        <vue-button-spinner
                                                class="btn btn-sm btn-primary"
                                                :isLoading="loading"
                                                :disabled="loading"
                                        >
                                            Update
                                        </vue-button-spinner>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    import Vue from "vue";
    import { mapGetters, mapActions } from 'vuex';
    import vSelect from 'vue-select';
    Vue.component('v-select', vSelect);
     export default {
        data() {
            return {
                xprops: {
                    module: 'SmtpConfig'
                }
            }
        },
        mounted() {
        },
        created() {
            this.fetchData();
        },
        destroyed() {
            this.resetState();
        },
        computed: {
            ...mapGetters('SmtpConfig', ['item', 'loading', 'errors']),
            downloadEnabled()
            {
                let value = _.get(this.item, 'enable-email-import', false);
                return (value === '1' || value === true || value === 1);
            }
        },
        methods: {
            ...mapActions('SmtpConfig', ['updateData', 'fetchData', 'setItemProperty'
            , 'resetErrors', 'resetState']),
            submitForm() {
                this.updateData()
                    .then((response) => {
                        this.$awn.success('Setting Update successfully.')
                    })
                    .catch((error) => {
                        this.$awn.info('OOPS! Error Occured.')
                    });
            },
            isActive(key)
            {
                let value = _.get(this.item, key, false);
                return (value === '1' || value === true || value === 1);
            },
            updateCheckbox(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.checked;
                this.setItemProperty({key: key, value: value});
            },
            updateInput(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.value;
                this.setItemProperty({key, value});
            }
        }
    }
</script>

<style scoped>

</style>
