<template>
    <div class="container-fluid">
        <filterable :endpoint="'catalog/vendors'" :params="this.query" :sortable="sortable" :filter-group="filters" ref="filterable">
            <template slot="title">
                <h6 class="m-0 font-weight-bold text-primary float-left"><i class="fa fa-cogs mr-2"></i>Vendors</h6>
            </template>
            <template slot="filter-nav" slot-scope="{ sortingClass, sortData }">
                <div class="float-right">
                    <ul class="nav nav-pills reports-top-tab">
                        <li class="ml-auto font-s-13 d-table dropdown pt-2">
                            <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="text-dark d-block mt-1 dropdown-toggle">
                                Sort by
                            </a>
                            <div aria-labelledby="navbarDropdown" class="sorting dropdown-menu">
                                <a v-for="s in sortable" class="dropdown-item" :class="sortingClass(s.value)" @click="sortData(s.value)">{{s.name}}</a>
                            </div>
                        </li>
                        <li class="ml-3 font-s-13 d-table pt-2">
                            <a href="#collapseFilter" data-toggle="collapse" class="btn-filter text-dark d-block mt-1"><i class="fa fa-filter"></i> Filters</a>
                        </li>
                        <li class="ml-3 font-s-13 d-table pt-2">
                            <router-link :to="{ name: 'catalog.vendors.create'}" class="text-dark d-block mt-1"><i class="fa fa-plus"></i> Add Vendor</router-link>
                        </li>
                    </ul>
                </div>
            </template>
            <template slot-scope="{ items, remove, sortingClass, sortData }" slot="content">
                <table class="table table-small mb-0">
                    <thead class="thead-light">
                    <tr class="sorting">
                        <th><a :class="sortingClass('id')" href="#" @click="sortData('Id')" >ID</a></th>
                        <th><a :class="sortingClass('name')" href="#" @click="sortData('name')" >Vendor</a></th>
                        <th><a :class="sortingClass('emails')" href="#" @click="sortData('emails')" >Notify</a></th>
                        <th width="50">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in items.data" v-bind:key="item.id">
                        <td>
                            {{ _.get(item,'id','-') }}
                        </td>
                        <td>
                            {{ _.get(item,'name','-') }}<br>
                            CODE: <span class="badge badge-primary">{{ _.get(item,'code','-') }}</span><br>
                            Export & Notify: <span class="badge badge-success" v-if="_.get(item,'notify',false)">YES</span>
                            <span class="badge badge-danger" v-if="!_.get(item,'notify',false)">No</span>
                        </td>
                        <td>
                            {{ _.join(_.get(item,'emails',[]), ', ') }}
                        </td>
                        <td class="actions">
                            <router-link :to="{ name: 'catalog.vendors.edit', params: { id: item.id }}" class="btn btn-sm btn-primary"><i class="far fa-edit"></i></router-link>
                            <a href="javascript:;" class="btn btn-sm btn-danger swa-confirm" @click="remove(item.id)" data-toggle="tooltip" data-original-title="Close" ><i class="far fa-trash-alt"></i></a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </filterable>
        <!-- /Row -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        data() {
            return {
                query: {
                    sort: "id",
                    sort_dir: "desc"
                },
            }
        },
        computed: {
            sortable() {
                return [
                    {value: "id", name: "Id"},
                    {value: "name", name: "Title"},
                    {value: "code", name: "Code"},
                    {value: "notify", name: "Notify"}
                ];
            },
            filters() {
                return [{
                    title: "Vendors",
                    filters: [
                        {name: "id", title: "Id"},
                        {name: "name", title: "Title", type: 'string'},
                        {name: "code", title: "Code", type: 'string'},
                        {name: "emails", title: "Emails", type: 'string'},
                        {name: "notify", title: "Notify", type: 'toggle'}
                    ]
                },
                ];
            }
        },
        methods:{}
    }
</script>
