var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "top-header" }, [
    _c(
      "nav",
      {
        staticClass:
          "navbar navbar-expand-md navbar-light bg-white shadow-sm fixed-top"
      },
      [
        !_vm._.isEmpty(_vm.title)
          ? _c(
              "router-link",
              { staticClass: "navbar-brand", attrs: { to: { name: "home" } } },
              [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "navbarSupportedContent" }
          },
          [
            _c("ul", { staticClass: "navbar-nav mr-auto" }, [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "home" } }
                    },
                    [_c("i", { staticClass: "fas fa-home" }), _vm._v(" Home")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "catalog.jobs" } }
                    },
                    [
                      _c("i", { staticClass: "fa fa-list ml-3" }),
                      _vm._v(" List jobs")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "catalog.jobs.create" } }
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus ml-3" }),
                      _vm._v(" Create jobs")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "catalog.products" } }
                    },
                    [
                      _c("i", { staticClass: "fa fa-list ml-3" }),
                      _vm._v(" List Products")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "catalog.products.create" } }
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus ml-3" }),
                      _vm._v(" Create Product")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "catalog.vendors" } }
                    },
                    [
                      _c("i", { staticClass: "fas fa-list ml-3" }),
                      _vm._v(" Vendors")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "catalog.orders" } }
                    },
                    [
                      _c("i", { staticClass: "fas fa-list ml-3" }),
                      _vm._v(" Orders")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "catalog.downloads" } }
                    },
                    [
                      _c("i", {
                        staticClass: "fas fa-cloud-download-alt ml-3"
                      }),
                      _vm._v(" Downloads")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "settings.general" } }
                    },
                    [
                      _c("i", { staticClass: "fas fa-cogs ml-3" }),
                      _vm._v(" Settings")
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "navbar-nav ml-auto" }, [
              _c("li", { staticClass: "nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: {
                      href: "#",
                      "data-toggle": "dropdown",
                      role: "button",
                      "aria-expanded": "false"
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-btn fa-picture-o" }),
                    _vm._v(" " + _vm._s(_vm.name) + " "),
                    _c("span", { staticClass: "caret" })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu dropdown-menu-right",
                    attrs: { "aria-labelledby": "navbarDropdown" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: { to: { name: "account" } }
                      },
                      [
                        _c("i", { staticClass: "fa mr-1 fa-user" }),
                        _vm._v("Manage Profile")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: { to: { name: "account.changePassword" } }
                      },
                      [
                        _c("i", {
                          staticClass: "fa mr-1 fa-btn fa-user-secret"
                        }),
                        _vm._v("Change password")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: {
                          href: _vm.logoutPath,
                          onclick:
                            "event.preventDefault();document.getElementById('logout-form').submit();"
                        }
                      },
                      [
                        _c("i", { staticClass: "fas mr-1 fa-sign-out-alt" }),
                        _vm._v("Logout")
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }