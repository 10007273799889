<template>
    <div class="container-fluid">
        <!-- Row -->
        <div class="row justify-content-center">
            <div class="col-6 text-center">
                <h1 class="text-danger">404 - Page not found</h1>
                <p >Page you are  looking for might have been removed,had it's name changed or temporarily unavailable</p>
                <router-link :to="{ name: 'home'}">Go Back To Home</router-link>
            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped>

</style>