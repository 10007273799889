import form from '../../../utils/form';

function initialState() {
    return {
        item: {
            name: null,
            email: null
        },
        form: {
            name: null,
            email: null
        },
        errors: {},
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    errors: state => state.errors,
    loading: state => state.loading,
};

const actions = {

    updateData({ commit, state, dispatch }) {
        commit('setLoading', true);
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)));
            //params.set('_method', 'PUT');
            axios.post('/account/edit-profile', params)
                .then(response => {
                    //commit('setItem', response.data.data);
                    resolve(response.data);
                })
                .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    fetchData({ commit, state, dispatch }) {
        axios.get('/account/profile')
            .then(response => {
                commit('setItemProperty', {key: "name", value: _.get(response.data, "name", null)});
                commit('setItemProperty', {key: "email", value: _.get(response.data, "email", null)});
            });
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
    setItemProperty({ commit }, {key, value}) {
        commit('setItemProperty', {key, value});
    },
    setDobFormatted({ commit }, value) {
        commit('setDobFormatted', value);
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setErrors(state, value) {
        state.errors = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setDobFormatted(state, value) {
        state.item.dob_formatted = value
    },
    setItemProperty(state, {key, value}) {
        _.set(state.item, key, value);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};
