import form from '../../../utils/form';

function initialState() {
    return {
        item: {
            'enable-email-import': false,
            'update-price-frequency': 2,
            'update-price': false,
            'manage-fraction': false,
            'base-price': null,
            'compare-price': null,
            'fraction-value': null,
            'fraction-multiplier': null,
        },
        form: {
            'enable-email-import': false,
            "download-frequency": null,
            'hostname': null,
            'port': null,
            'username': null,
            'password': null,
            'update-price-frequency': 2,
            'update-price': false,
            'manage-fraction': false,
            'base-price': null,
            'compare-price': null,
            'fraction-value': null,
            'fraction-multiplier': null,
        },
        errors: {},
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    errors: state => state.errors,
    loading: state => state.loading
};

const actions = {
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true);
        commit('setErrors', {});
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)));
            axios.post(`smtp/settings`, params)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    fetchData({ commit, dispatch}) {
        axios.get(`smtp/settings`)
            .then(response => {
                if(!_.isEmpty(response.data)){
                    commit('setItem', _.get(response, "data.settings", {}));
                }
            });
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
    setItemProperty({ commit }, {key, value}) {
        commit('setItemProperty', {key, value})
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

const mutations = {
    setItem(state, item) {
        state.item = _.extend({}, state.form, state.item, item);
    },
    setItemProperty(state, {key, value}){
        state.item[key] = value;
    },
    setErrors(state, value) {
        state.errors = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },

};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};

