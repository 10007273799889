<template>
   <div>
    <span class="tooltip-trigger" ref="trigger" @mouseenter="toggle" @mouseleave="toggle">
      <slot></slot>
    </span>
    <transition name="fade" @after-leave="doDestory">
      <div :class="tooltipClass"
        v-if="show"
        ref="popover" role="tooltip">
        <div class="tooltip-arrow"></div>
        <div class="tooltip-content">
          <slot name="content"><div>{{content}}</div></slot>
        </div>
      </div>
    </transition> 
  </div>
</template>
<script>
  export default {
    props: {
      content: {
        default: null
      },
      position: {
        default: "top"
      }
    },
    data() {
      return {
        show: false
      }
    },
    computed:{
      tooltipClass(){
        return this.show? `tooltip fade bs-tooltip-${this.position} show`:`tooltip fade bs-tooltip-${this.position}`;
      }
    },
    methods: {
      toggle() {
        this.show = !this.show
      },
      doDestory() {
        // alert('ok')
      }
    }
  }
</script>