var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c(
        "filterable",
        {
          ref: "filterable",
          attrs: {
            endpoint: "catalog/products",
            params: this.query,
            sortable: _vm.sortable,
            "filter-group": _vm.filters
          },
          scopedSlots: _vm._u([
            {
              key: "filter-nav",
              fn: function(ref) {
                var sortingClass = ref.sortingClass
                var sortData = ref.sortData
                return [
                  _c("div", { staticClass: "float-right" }, [
                    _c("ul", { staticClass: "nav nav-pills reports-top-tab" }, [
                      _c(
                        "li",
                        {
                          staticClass: "ml-auto font-s-13 d-table dropdown pt-2"
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-dark d-block mt-1 dropdown-toggle",
                              attrs: {
                                href: "#",
                                "data-toggle": "dropdown",
                                role: "button",
                                "aria-expanded": "false"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Sort by\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "sorting dropdown-menu",
                              attrs: { "aria-labelledby": "navbarDropdown" }
                            },
                            _vm._l(_vm.sortable, function(s) {
                              return _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class: sortingClass(s.value),
                                  on: {
                                    click: function($event) {
                                      return sortData(s.value)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(s.name))]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("li", { staticClass: "ml-3 font-s-13 d-table pt-2" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn-filter text-dark d-block mt-1",
                            attrs: {
                              href: "#collapseFilter",
                              "data-toggle": "collapse"
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-filter" }),
                            _vm._v(" Filters")
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "ml-3 font-s-13 d-table pt-2" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "text-dark d-block mt-1",
                              attrs: { to: { name: "catalog.products.create" } }
                            },
                            [
                              _c("i", { staticClass: "fa fa-plus" }),
                              _vm._v(" Add Product")
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              }
            },
            {
              key: "content",
              fn: function(ref) {
                var items = ref.items
                var remove = ref.remove
                var sortingClass = ref.sortingClass
                var sortData = ref.sortData
                return [
                  _c("table", { staticClass: "table table-small mb-0" }, [
                    _c("thead", { staticClass: "thead-light" }, [
                      _c("tr", { staticClass: "sorting" }, [
                        _c("th", [
                          _c(
                            "a",
                            {
                              class: sortingClass("id"),
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return sortData("Id")
                                }
                              }
                            },
                            [_vm._v("ID")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "a",
                            {
                              class: sortingClass("name"),
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return sortData("name")
                                }
                              }
                            },
                            [_vm._v("Product")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "a",
                            {
                              class: sortingClass("quantity"),
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return sortData("quantity")
                                }
                              }
                            },
                            [_vm._v("Quantity")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "a",
                            {
                              class: sortingClass("price"),
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return sortData("price")
                                }
                              }
                            },
                            [_vm._v("Price")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { width: "50" } }, [_vm._v("Action")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(items.data, function(item) {
                        return _c("tr", { key: item.id }, [
                          _c("td", [
                            _vm._v(
                              "\n                        Local ID: " +
                                _vm._s(_vm._.get(item, "id", "-"))
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                        Variant ID: " +
                                _vm._s(_vm._.get(item, "variantId", "-"))
                            ),
                            _c("br"),
                            _vm._v("\n                        Vendor: "),
                            _c("span", { staticClass: "badge badge-primary" }, [
                              _vm._v(_vm._s(_vm._.get(item, "vendor", "-")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm._.get(item, "name", "-"))
                            ),
                            _c("br"),
                            _vm._v("\n                        SKU: "),
                            _c("span", { staticClass: "badge badge-primary" }, [
                              _vm._v(_vm._s(_vm._.get(item, "sku", "-")))
                            ]),
                            _c("br"),
                            _vm._v("\n                        Internal ID: "),
                            _c("span", { staticClass: "badge badge-primary" }, [
                              _vm._v(_vm._s(_vm._.get(item, "internalId", "-")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm._.get(item, "quantity", "-")) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm._.get(item, "price", "-")) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "actions" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn btn-sm btn-primary",
                                  attrs: {
                                    to: {
                                      name: "catalog.products.edit",
                                      params: { id: item.id }
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "far fa-edit" })]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-sm btn-danger swa-confirm",
                                  attrs: {
                                    href: "javascript:;",
                                    "data-toggle": "tooltip",
                                    "data-original-title": "Close"
                                  },
                                  on: {
                                    click: function($event) {
                                      return remove(item.id)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "far fa-trash-alt" })]
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "h6",
              { staticClass: "m-0 font-weight-bold text-primary float-left" },
              [_c("i", { staticClass: "fa fa-cogs mr-2" }), _vm._v("Products")]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }