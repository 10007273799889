function initialState() {
    return {
        user: {},
        errors: {}
    }
}

const getters = {
    user: state => state.user,
    errors: state => state.errors
};

const actions = {
    getUser({ commit, dispatch }) {
        axios.get('account/profile')
            .then(response => {

                commit('setUser', _.get(response, "data.data", {}));
            });
    },
    logoutUser({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.post('logout')
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        });

    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
    resetUser({ commit }) {
        commit('setUser', {});
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

const mutations = {
    setUser(state, value) {
        state.user = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },

};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};
