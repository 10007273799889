<template>
    <div class="container-fluid">
        <filterable :endpoint="'catalog/jobs'" :params="this.query" :sortable="sortable" :filter-group="filters" ref="filterable">
            <template slot="title">
                <h6 class="m-0 font-weight-bold text-primary float-left"><i class="fa fa-cogs mr-2"></i>Jobs</h6>
            </template>
            <template slot="filter-nav" slot-scope="{ sortingClass, sortData }">
                <div class="float-right">
                    <ul class="nav nav-pills reports-top-tab">
                        <li class="ml-auto font-s-13 d-table dropdown pt-2">
                            <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="text-dark d-block mt-1 dropdown-toggle">
                                Sort by
                            </a>
                            <div aria-labelledby="navbarDropdown" class="sorting dropdown-menu">
                                <a v-for="s in sortable" class="dropdown-item" :class="sortingClass(s.value)" @click="sortData(s.value)">{{s.name}}</a>
                            </div>
                        </li>
                        <li class="ml-3 font-s-13 d-table pt-2">
                            <a href="#collapseFilter" data-toggle="collapse" class="btn-filter text-dark d-block mt-1"><i class="fa fa-filter"></i> Filters</a>
                        </li>
                        <li class="ml-3 font-s-13 d-table pt-2">
                            <router-link :to="{ name: 'catalog.jobs.create'}" class="text-dark d-block mt-1"><i class="fa fa-plus"></i> Add Job</router-link>
                        </li>
                    </ul>
                </div>
            </template>
            <template slot-scope="{ items, remove, sortingClass, sortData }" slot="content">
                <table class="table table-small mb-0">
                    <thead class="thead-light">
                    <tr class="sorting">
                        <th><a :class="sortingClass('name')" href="#" @click="sortData('name')" >Name</a></th>
                        <th><a :class="sortingClass('update_price')" href="#" @click="sortData('update_price')" >Published</a></th>
                        <th><a :class="sortingClass('status')" href="#" @click="sortData('status')" >Status</a></th>
                        <th width="50">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in items.data" v-bind:key="item.id">
                        <td>
                            <span class="font-weight-bold">{{_.get(item,'name','-')}}</span><br>
                        </td>
                        <td>
                            <span :class="{'badge badge-danger': !item.update_price, 'badge badge-success': item.update_price}">
                                <i class="fa" :class="{'fa fa-tasks': !item.update_price, 'fa fa-check': item.update_price}"></i>
                                <template v-if="!item.update_price">No</template>
                                <template v-if="item.update_price">Yes</template>
                            </span>
                            <span :class="{'badge badge-danger': !item.create_product, 'badge badge-success': item.create_product}">
                                <i class="fa" :class="{'fa fa-tasks': !item.create_product, 'fa fa-check': item.create_product}"></i>
                                <template v-if="!item.create_product">Don't Create</template>
                                <template v-if="item.create_product">Create Missing Product</template>
                            </span>
                            <template v-if="item.update_price">
                                <br>
                                Base Price: <strong>{{ item.base_price }}</strong><br>
                                Compare Price: <strong>{{ item.compare_price }}</strong><br>
                            </template>
                            <span class="badge badge-success" v-if="item.manage_fraction">
                                <i class="fa fa-check"></i> Manage Price Fraction
                            </span>
                            <template v-if="item.manage_fraction">
                                <br>
                                Fraction Value: <strong>{{ item.fraction_value }}</strong><br>
                                Fraction Multiplier: <strong>{{ item.fraction_multiplier }}</strong><br>
                            </template>
                            <span class="badge badge-success" v-if="item.update_internalId">
                                <i class="fa fa-check"></i> Update Internal ID
                            </span>
                        </td>
                        <td>
                            <span class="badge" :class="{'badge-danger': !isCompleted(item.status), 'badge-success': isCompleted(item.status)}">
                                <i class="fa" :class="{'fa-tasks': !isCompleted(item.status), 'fa-check': isCompleted(item.status)}"></i> {{ item.status }}
                            </span>
                        </td>
                        <td class="actions">
                            <a v-if="item.is_downloadable" :href="`/catalog/jobs/download/${item.id}`" class="m-2"><i class="fas fa-cloud-download-alt"></i></a>
                            <router-link :to="{ name: 'catalog.jobs.edit', params: { id: item.id }}" class="btn btn-sm btn-primary"><i class="far fa-edit"></i></router-link>
                            <a href="javascript:;" class="btn btn-sm btn-danger swa-confirm" @click="remove(item.id)" data-toggle="tooltip" data-original-title="Close" ><i class="far fa-trash-alt"></i></a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </filterable>
        <!-- /Row -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        data() {
            return {
                query: {
                    sort: "id",
                    sort_dir: "desc"
                },
            }
        },
        computed: {
            sortable() {
                return [
                    {value: "id", name: "Id"},
                    {value: "name", name: "Title"},
                    {value: "status", name: "Status"},
                    {value: "update_price", name: "Update Price"},
                    {value: "create_product", name: "Create Product"},
                    {value: "manage_fraction", name: "Manage Fraction"},
                    {value: "base_price", name: "Base Price"},
                    {value: "compare_price", name: "Compare Price"},
                    {value: "fraction_value", name: "Fraction Value"},
                    {value: "fraction_multiplier", name: "Fraction Multiplier"},
                ];
            },
            filters() {
                return [{
                    title: "Jobs",
                    filters: [
                        {name: "id", title: "Id"},
                        {name: "name", title: "Title", type: 'string'},
                        {name: "status", title: "Status", type: 'string'},
                        {name: "update_price", title: "Update Price", type: 'toggle'},
                        {name: "create_product", title: "Create Product", type: 'toggle'},
                        {name: "manage_fraction", title: "Manage Fraction", type: 'toggle'},
                        {name: "base_price", title: "Base Price", type: 'numeric'},
                        {name: "compare_price", title: "Compare Price", type: 'numeric'},
                        {name: "fraction_value", title: "Fraction Value", type: 'numeric'},
                        {name: "fraction_multiplier", title: "Fraction Multiplier", type: 'numeric'},
                    ]
                },
                ];
            }
        },
        methods:{
            isCompleted(status){
                return status === "Completed";
            }
        }
    }
</script>
