import Vue from 'vue';
import VueRouter from 'vue-router';

import NotFound from '../components/NotFound';
import Dashboard from '../components/Dashboard';
import AccountProfile from '../components/Account/Profile';
import AccountEditProfile from '../components/Account/EditProfile';
import AccountEditPicture from '../components/Account/EditPicture';
import AccountChangePassword from '../components/Account/ChangePassword';
import JobsIndex from '../components/Jobs/Index';
import JobsCreate from '../components/Jobs/Create';
import JobsEdit from '../components/Jobs/Edit';
import ProductsIndex from '../components/Products/Index';
import ProductsCreate from '../components/Products/Create';
import ProductsEdit from '../components/Products/Edit';
import VendorsIndex from '../components/Vendors/Index';
import VendorsCreate from '../components/Vendors/Create';
import VendorsEdit from '../components/Vendors/Edit';
import DownloadsIndex from '../components/Downloads/Index';
import DownloadsCreate from '../components/Downloads/Create';
import OrdersIndex from '../components/Orders/Index';
// Settings
import GeneralSettings from '../components/Settings/General';
import SmtpSettings from '../components/Settings/Smtp';

Vue.use(VueRouter);

const routes = [
    { path: '/', component: Dashboard, name: 'home' },
    { path: '/account', component: AccountProfile, name: 'account' },
    { path: '/account/edit-profile', component: AccountEditProfile, name: 'account.editProfile' },
    { path: '/account/change-password', component: AccountChangePassword, name: 'account.changePassword' },
    { path: '/account/edit-picture', component: AccountEditPicture, name: 'account.editPicture' },
    { path: '/catalog/jobs', component: JobsIndex, name: 'catalog.jobs' },
    { path: '/catalog/jobs/create', component: JobsCreate, name: 'catalog.jobs.create' },
    { path: '/catalog/jobs/:id/edit', component: JobsEdit, name: 'catalog.jobs.edit' },
    { path: '/catalog/products', component: ProductsIndex, name: 'catalog.products' },
    { path: '/catalog/products/create', component: ProductsCreate, name: 'catalog.products.create' },
    { path: '/catalog/products/:id/edit', component: ProductsEdit, name: 'catalog.products.edit' },
    { path: '/catalog/vendors', component: VendorsIndex, name: 'catalog.vendors' },
    { path: '/catalog/vendors/create', component: VendorsCreate, name: 'catalog.vendors.create' },
    { path: '/catalog/vendors/:id/edit', component: VendorsEdit, name: 'catalog.vendors.edit' },
    { path: '/catalog/downloads', component: DownloadsIndex, name: 'catalog.downloads' },
    { path: '/catalog/downloads/create', component: DownloadsCreate, name: 'catalog.downloads.create' },
    { path: '/catalog/orders', component: OrdersIndex, name: 'catalog.orders' },
    { path: '/settings/general', component: GeneralSettings, name: 'settings.general' },
    { path: '/settings/mailbox', component: SmtpSettings, name: 'settings.mailbox' },
    { path: '*', component: NotFound, name: 'not.found' },
];

const router = new VueRouter({
    mode: 'history',
    base: '/v1',
    routes
});
export default router;
