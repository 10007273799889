<template>
<div id="accordion" class="left-sidebar">
    <div class="mt-3">
        <div class="" id="headingfive">
            <h5 class="mb-0">
                <button class="btn btn-link " data-toggle="collapse" data-target="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
                      <i class="far fa-chart-bar"></i> Account Settings
                </button>
            </h5>
        </div>
        <div id="collapsefive" class="collapse show" aria-labelledby="headingfive" data-parent="#accordion">
            <div class="px-2">
                <ul class="pl-4">
                    <li class="py-1">
                        <router-link :to="{ name: 'account'}">My Account</router-link>
                    </li>
                    <li class="py-1">
                        <router-link :to="{ name: 'account.editProfile'}">Edit Profile</router-link>
                    </li>
                    <li class="py-1">
                        <router-link :to="{ name: 'account.changePassword'}">Change Password</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        computed:{

        }
    }
</script>

<style scoped>

</style>
