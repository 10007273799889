var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-2 col-12 bg-white" },
        [_c("settings-sidebar")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-10 col-12" }, [
        _c("div", { staticClass: "mt-3" }, [
          _c(
            "h4",
            { staticClass: "mb-3" },
            [
              _vm._v(
                "\n                 General Settings\n                    "
              ),
              _c("back-button", { staticClass: "float-right" })
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", {}, [
            _c("div", {}, [
              _c(
                "form",
                {
                  attrs: { novalidate: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-6 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group required" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label mb-10",
                              attrs: { for: "site-title" }
                            },
                            [_vm._v("App Name")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              id: "site-title",
                              type: "text",
                              name: "site-title",
                              required: ""
                            },
                            domProps: {
                              value: _vm._.get(_vm.item, "site-title", null)
                            },
                            on: { input: _vm.updateInput }
                          }),
                          _vm._v(" "),
                          _c("input-error", {
                            attrs: { errors: _vm.errors, name: "site-title" }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-6 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group required" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label mb-10",
                              attrs: { for: "site-email" }
                            },
                            [_vm._v("Admin Email")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              id: "site-email",
                              type: "text",
                              name: "site-email",
                              required: ""
                            },
                            domProps: {
                              value: _vm._.get(_vm.item, "site-email", null)
                            },
                            on: { input: _vm.updateInput }
                          }),
                          _vm._v(" "),
                          _c("input-error", {
                            attrs: { errors: _vm.errors, name: "site-email" }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-12 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group required" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label mb-10",
                              attrs: { for: "site-description" }
                            },
                            [_vm._v("About App")]
                          ),
                          _vm._v(" "),
                          _c("textarea", {
                            staticClass: "form-control",
                            attrs: {
                              id: "site-description",
                              type: "text",
                              name: "site-description",
                              required: ""
                            },
                            domProps: {
                              value: _vm._.get(
                                _vm.item,
                                "site-description",
                                null
                              )
                            },
                            on: { input: _vm.updateInput }
                          }),
                          _vm._v(" "),
                          _c("input-error", {
                            attrs: {
                              errors: _vm.errors,
                              name: "site-description"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-6 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group required" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label mb-10",
                              attrs: { for: "default-timezone" }
                            },
                            [_vm._v("TimeZone")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-select",
                            {
                              attrs: {
                                label: "zone",
                                value: _vm._.get(
                                  _vm.item,
                                  "default-timezone",
                                  null
                                ),
                                id: "default-timezone",
                                filterable: true,
                                options: _vm.timezones,
                                placeholder: "Select timezone"
                              },
                              on: { input: _vm.updateTimeZone }
                            },
                            [
                              _c("template", { slot: "no-options" }, [
                                _vm._v(
                                  "\n                                            type to search timezones..\n                                        "
                                )
                              ])
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("input-error", {
                            attrs: {
                              errors: _vm.errors,
                              name: "default-timezone"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-6 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group required" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label mb-10",
                              attrs: { for: "records-per-page" }
                            },
                            [_vm._v("Records per page")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              id: "records-per-page",
                              type: "text",
                              name: "records-per-page",
                              required: ""
                            },
                            domProps: { value: _vm.item["records-per-page"] },
                            on: { input: _vm.updateInput }
                          }),
                          _vm._v(" "),
                          _c("input-error", {
                            attrs: {
                              errors: _vm.errors,
                              name: "records-per-page"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-check form-group" },
                        [
                          _c("input", {
                            staticClass: "form-check-input",
                            attrs: {
                              id: "download-orders",
                              type: "checkbox",
                              name: "download-orders",
                              "true-value": 1,
                              "false-value": 0,
                              required: ""
                            },
                            domProps: { checked: _vm.downloadEnabled },
                            on: { input: _vm.updateCheckbox }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label mb-10",
                              attrs: { for: "download-orders" }
                            },
                            [_vm._v("Automatically download orders to csv?")]
                          ),
                          _vm._v(" "),
                          _c("small", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              " If checked orders will be downloaded every " +
                                _vm._s(
                                  _vm._.get(_vm.item, "download-frequency", 2)
                                ) +
                                " Hours"
                            )
                          ]),
                          _vm._v(" "),
                          _c("input-error", {
                            attrs: {
                              errors: _vm.errors,
                              name: "download-orders"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.downloadEnabled
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group required" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label mb-10",
                                    attrs: { for: "download-frequency" }
                                  },
                                  [_vm._v("Download Frequency")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "download-frequency",
                                    type: "number",
                                    name: "download-frequency",
                                    required: ""
                                  },
                                  domProps: {
                                    value: _vm._.get(
                                      _vm.item,
                                      "download-frequency",
                                      2
                                    )
                                  },
                                  on: { input: _vm.updateInput }
                                }),
                                _vm._v(" "),
                                _c("input-error", {
                                  attrs: {
                                    errors: _vm.errors,
                                    name: "download-frequency"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-check form-group" },
                              [
                                _c("input", {
                                  staticClass: "form-check-input",
                                  attrs: {
                                    id: "email-downloaded-orders",
                                    type: "checkbox",
                                    name: "email-downloaded-orders",
                                    "true-value": 1,
                                    "false-value": 0,
                                    required: ""
                                  },
                                  domProps: { checked: _vm.emailDownloaded },
                                  on: { input: _vm.updateCheckbox }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label mb-10",
                                    attrs: { for: "download-orders" }
                                  },
                                  [_vm._v("Email downloaded orders?")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "form-text text-muted" },
                                  [
                                    _vm._v(
                                      " If checked downloaded orders will be emailed."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input-error", {
                                  attrs: {
                                    errors: _vm.errors,
                                    name: "email-downloaded-orders"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group required" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label mb-10",
                                    attrs: { for: "orders-email" }
                                  },
                                  [_vm._v("Download alert email")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "orders-email",
                                    type: "email",
                                    name: "orders-email",
                                    required: ""
                                  },
                                  domProps: {
                                    value: _vm._.get(
                                      _vm.item,
                                      "orders-email",
                                      null
                                    )
                                  },
                                  on: { input: _vm.updateInput }
                                }),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "form-text text-muted" },
                                  [
                                    _vm._v(
                                      "Enter comma(,) separated emails for multiple email addresses."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input-error", {
                                  attrs: {
                                    errors: _vm.errors,
                                    name: "orders-email"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-12 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group text-right" },
                        [
                          _c(
                            "vue-button-spinner",
                            {
                              staticClass: "btn btn-sm btn-primary",
                              attrs: {
                                isLoading: _vm.loading,
                                disabled: _vm.loading
                              }
                            },
                            [
                              _vm._v(
                                "\n                                        Update\n                                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }