import Vue from "vue";
import Vuex from "vuex";

import Filterable from './modules/Common/Filterable';
import Auth from './modules/Accounts/Auth';
import AccountsChangePassword from './modules/Accounts/ChangePassword';
import AccountsProfile from './modules/Accounts/Profile';
import AccountsEditProfile from './modules/Accounts/EditProfile';
import AccountsEditPicture from './modules/Accounts/EditPicture';
import JobsForm from './modules/Jobs/Form';
import DownloadsForm from './modules/Downloads/Form';
import ProductsForm from './modules/Products/Form';
import VendorsForm from './modules/Vendors/Form';
import SettingsForm from './modules/Settings/Form';
import SmtpConfig from './modules/Settings/SmtpConfig';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        Filterable,
        Auth,
        AccountsChangePassword,
        AccountsProfile,
        AccountsEditProfile,
        AccountsEditPicture,
        SettingsForm,
        SmtpConfig,
        JobsForm,
        DownloadsForm,
        ProductsForm,
        VendorsForm
    },
    strict: debug,
});
