<template>
    <div></div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: {
            ...mapGetters('AccountRules', ['departments', 'permissions', 'roles'])
        },
        created() {
            this.$eventHub.$on('rules-update', this.rulesUpdate);
        },
        methods: {
            rulesUpdate() {
                this.$ability.update([
                    { subject: 'departments', actions: this.departments },
                    { subject: 'permissions', actions: this.permissions },
                    { subject: 'roles', actions: this.roles }
                ]);
            }
        }
    }
</script>

<style scoped>

</style>