var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-2 col-12 bg-light sidebar" },
        [_c("profile-sidebar")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-10 col-12" }, [
        _c("div", { staticClass: "mt-4" }, [
          _c(
            "h4",
            { staticClass: "mb-3" },
            [
              _vm._v(
                "\n                    Edit Profile\n                    "
              ),
              _c("back-button", { staticClass: "float-right" })
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", {}, [
            _c("div", {}, [
              _c(
                "form",
                {
                  attrs: { novalidate: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-6 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label mb-10",
                              attrs: { for: "name" }
                            },
                            [_vm._v("Name")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              id: "name",
                              type: "text",
                              name: "name",
                              required: ""
                            },
                            domProps: {
                              value: _vm._.get(_vm.item, "name", null)
                            },
                            on: { input: _vm.updateInput }
                          }),
                          _vm._v(" "),
                          _c("input-error", {
                            attrs: { errors: _vm.errors, name: "name" }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-6 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label mb-10",
                              attrs: { for: "email" }
                            },
                            [_vm._v("Email")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              id: "email",
                              type: "text",
                              name: "email",
                              required: ""
                            },
                            domProps: {
                              value: _vm._.get(_vm.item, "email", null)
                            },
                            on: { input: _vm.updateInput }
                          }),
                          _vm._v(" "),
                          _c("input-error", {
                            attrs: { errors: _vm.errors, name: "email" }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-12 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group text-right" },
                        [
                          _c(
                            "vue-button-spinner",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                isLoading: _vm.loading,
                                disabled: _vm.loading
                              }
                            },
                            [
                              _vm._v(
                                "\n                                            Update\n                                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }