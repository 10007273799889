<template>
    <div class="container-fluid">
        <filterable :endpoint="'catalog/products'" :params="this.query" :sortable="sortable" :filter-group="filters" ref="filterable">
            <template slot="title">
                <h6 class="m-0 font-weight-bold text-primary float-left"><i class="fa fa-cogs mr-2"></i>Products</h6>
            </template>
            <template slot="filter-nav" slot-scope="{ sortingClass, sortData }">
                <div class="float-right">
                    <ul class="nav nav-pills reports-top-tab">
                        <li class="ml-auto font-s-13 d-table dropdown pt-2">
                            <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="text-dark d-block mt-1 dropdown-toggle">
                                Sort by
                            </a>
                            <div aria-labelledby="navbarDropdown" class="sorting dropdown-menu">
                                <a v-for="s in sortable" class="dropdown-item" :class="sortingClass(s.value)" @click="sortData(s.value)">{{s.name}}</a>
                            </div>
                        </li>
                        <li class="ml-3 font-s-13 d-table pt-2">
                            <a href="#collapseFilter" data-toggle="collapse" class="btn-filter text-dark d-block mt-1"><i class="fa fa-filter"></i> Filters</a>
                        </li>
                        <li class="ml-3 font-s-13 d-table pt-2">
                            <router-link :to="{ name: 'catalog.products.create'}" class="text-dark d-block mt-1"><i class="fa fa-plus"></i> Add Product</router-link>
                        </li>
                    </ul>
                </div>
            </template>
            <template slot-scope="{ items, remove, sortingClass, sortData }" slot="content">
                <table class="table table-small mb-0">
                    <thead class="thead-light">
                    <tr class="sorting">
                        <th><a :class="sortingClass('id')" href="#" @click="sortData('Id')" >ID</a></th>
                        <th><a :class="sortingClass('name')" href="#" @click="sortData('name')" >Product</a></th>
                        <th><a :class="sortingClass('quantity')" href="#" @click="sortData('quantity')" >Quantity</a></th>
                        <th><a :class="sortingClass('price')" href="#" @click="sortData('price')" >Price</a></th>
                        <th width="50">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in items.data" v-bind:key="item.id">
                        <td>
                            Local ID: {{ _.get(item,'id','-') }}<br>
                            Variant ID: {{ _.get(item,'variantId','-') }}<br>
                            Vendor: <span class="badge badge-primary">{{ _.get(item,'vendor','-') }}</span>
                        </td>
                        <td>
                            {{ _.get(item,'name','-') }}<br>
                            SKU: <span class="badge badge-primary">{{ _.get(item,'sku','-') }}</span><br>
                            Internal ID: <span class="badge badge-primary">{{ _.get(item,'internalId','-') }}</span>
                        </td>
                        <td>
                            {{ _.get(item,'quantity','-') }}
                        </td>
                        <td>
                            {{ _.get(item,'price','-') }}
                        </td>
                        <td class="actions">
                            <router-link :to="{ name: 'catalog.products.edit', params: { id: item.id }}" class="btn btn-sm btn-primary"><i class="far fa-edit"></i></router-link>
                            <a href="javascript:;" class="btn btn-sm btn-danger swa-confirm" @click="remove(item.id)" data-toggle="tooltip" data-original-title="Close" ><i class="far fa-trash-alt"></i></a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </filterable>
        <!-- /Row -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        data() {
            return {
                query: {
                    sort: "id",
                    sort_dir: "desc"
                },
            }
        },
        computed: {
            sortable() {
                return [
                    {value: "id", name: "Id"},
                    {value: "name", name: "Title"},
                    {value: "sku", name: "SKU"},
                    {value: "vendor", name: "Vendor"},
                    {value: "internalId", name: "Internal ID"},
                    {value: "price", name: "Price"},
                    {value: "quantity", name: "Quantity"}
                ];
            },
            filters() {
                return [{
                    title: "Products",
                    filters: [
                        {name: "id", title: "Id"},
                        {name: "name", title: "Title", type: 'string'},
                        {name: "sku", title: "SKU", type: 'string'},
                        {name: "vendor", title: "Vendor", type: 'string'},
                        {name: "internalId", title: "Internal ID", type: 'string'},
                        {name: "price", title: "Price", type: 'numeric'},
                        {name: "quantity", title: "Quantity", type: 'numeric'}
                    ]
                },
                ];
            }
        },
        methods:{}
    }
</script>
