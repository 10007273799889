var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "left-sidebar", attrs: { id: "accordion" } },
    [
      _c("div", { staticClass: "mt-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse show",
            attrs: {
              id: "collapsefive",
              "aria-labelledby": "headingfive",
              "data-parent": "#accordion"
            }
          },
          [
            _c("div", { staticClass: "px-2" }, [
              _c("ul", { staticClass: "pl-4" }, [
                _c(
                  "li",
                  { staticClass: "py-1" },
                  [
                    _c("router-link", { attrs: { to: { name: "account" } } }, [
                      _vm._v("My Account")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "py-1" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "account.editProfile" } } },
                      [_vm._v("Edit Profile")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "py-1" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "account.editPicture" } } },
                      [_vm._v("Edit Picture")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "py-1" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "account.changePassword" } } },
                      [_vm._v("Change Password")]
                    )
                  ],
                  1
                )
              ])
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "headingfive" } }, [
      _c("h5", { staticClass: "mb-0" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-link ",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#collapsefive",
              "aria-expanded": "true",
              "aria-controls": "collapsefive"
            }
          },
          [
            _c("i", { staticClass: "far fa-chart-bar" }),
            _vm._v(" Account Settings\n                ")
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }