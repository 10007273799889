import form from "../../../utils/form";

function initialState() {
    return {
        file: null,
        item: {
            name: null,
            status: null,
            update_price: false,
            create_product: false,
            manage_fraction: false,
            base_price: null,
            compare_price: null,
            fraction_value: null,
            fraction_multiplier: null,
            update_internalId:false,
            options: []
        },
        defaultOptions: {
            "operator": "equal_to",
            "query_one": null,
            "query_two": null,
            "base_price": null,
            "compare_price": null
        },
        form: {
            name: null,
            status: null,
            update_price: null,
            create_product: null,
            manage_fraction: null,
            base_price: null,
            compare_price: null,
            fraction_value: null,
            fraction_multiplier: null,
            update_internalId:false,
            options: []
        },
        statuses: [
            "Queued",
            "Failed",
            "Processing",
            "Completed"
        ],
        operators: [
            {'id':'equal_to', "label":"Equals TO (==)"},
            {'id':'not_equal_to', "label":"Not Equals TO (!=)"},
            {'id':'less_than', "label":"Less Than (<)"},
            {'id':'greater_than', "label":"Greater Than (>)"},
            {'id':'less_than_or_equal_to', "label":"Less Than Or Equals TO (<=)"},
            {'id':'greater_than_or_equal_to', "label":"Greater Than Or Equals TO (>=)"},
            {'id':'between', "label":"In Between"},
            {'id':'not_between', "label":"Not In Between"}
        ],
        errors: {},
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    errors: state => state.errors,
    loading: state => state.loading,
    statuses: state => state.statuses,
    operators: state => state.operators
};

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true);
        commit('setErrors', {});
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)),{indices: true});
            params.set("path", state.file);
            axios.post('catalog/jobs', params)
                .then(response => {
                    commit('resetForm');
                    resolve(response.data);
                })
                 .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    updateData({ commit, state }) {
        commit('setLoading', true);
        commit('setErrors', {});
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)), {indices: true});
            params.set('_method', 'PUT');
            if(state.file)
            {
                params.set("path", state.file);
            }
            axios.post(`catalog/jobs/${state.item.id}`, params)
                .then(response => {
                    resolve(response.data);
                })
                 .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    fetchData({ commit, dispatch, state }, id) {
        axios.get(`catalog/jobs/${id}`)
            .then(response => {
                commit('setItem', _.get(response.data, "data", {}));
            });
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
    setItemProperty({ commit }, {key, value}) {
        commit('setItemProperty', {key, value})
    },
    setFile({ commit }, file) {
        commit('setFile', file);
    },
    addOption({commit}) {
        commit('addOption');
    },
    removeOption({commit, state}, value) {
        let id = _.get(state, `item.options.${value}.id`, null);
        if(id){
            axios.delete(`catalog/jobs/option/${id}`)
                .then(response => {
                    commit('removeOption', value);
                });
        }else{
            commit('removeOption', value);
        }
    },
    setOptionProperty({commit}, {index, key, value}) {
        commit("setOptionProperty", {index, key, value});
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setFile(state, value) {
        state.file = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    resetForm(state) {
        state.file = null;
        _.merge(state.item, state.form);
    },
    setItemProperty(state, {key, value}){
        _.set(state.item, key, value);
    },
    setStatuses(state, value) {
        state.statuses = value
    },
    addOption(state) {
        state.item.options.push(Object.assign({}, state.defaultOptions));
    },
    removeOption(state, value) {
        state.item.options.splice(value, 1);
    },
    setOptionProperty(state, {index, key, value}){
        _.set(state.item.options, `${index}.${key}`, value);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};

