var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-12" }, [
        _c("div", { staticClass: "mt-4" }, [
          _c(
            "h4",
            {},
            [
              _vm._v(
                "\n                    Add Products\n                    "
              ),
              _c("back-button", { staticClass: "float-right" })
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "form",
              {
                attrs: { novalidate: "" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submitForm($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "name" }
                          },
                          [_vm._v("Vendor")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "name",
                            type: "text",
                            name: "name",
                            required: ""
                          },
                          domProps: {
                            value: _vm._.get(_vm.item, "name", null)
                          },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "name" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "code" }
                          },
                          [_vm._v("Code")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "code",
                            type: "text",
                            name: "code",
                            required: ""
                          },
                          domProps: {
                            value: _vm._.get(_vm.item, "code", null)
                          },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "code" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.columns
                    ? _c("div", { staticClass: "col-12" }, [
                        _c("h5", [_vm._v("Quantity Conditions")]),
                        _c("hr"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _vm._l(_vm.item.options, function(
                              option,
                              optionIndex
                            ) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "col-2" },
                                  [
                                    _c(
                                      "v-select",
                                      {
                                        attrs: {
                                          value: option.column,
                                          label: "label",
                                          id: "column_" + optionIndex,
                                          filterable: false,
                                          options: _vm.columns,
                                          placeholder: "Select operator"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.updateColumn(
                                              $event,
                                              "column",
                                              optionIndex
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("template", { slot: "no-options" }, [
                                          _vm._v(
                                            "\n                                                    Select Column\n                                                "
                                          )
                                        ])
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c("input-error", {
                                      attrs: {
                                        errors: _vm.errors,
                                        name: optionIndex + ".column"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                option.column
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "col-2" },
                                        [
                                          _c(
                                            "v-select",
                                            {
                                              attrs: {
                                                value: option.operator,
                                                label: "label",
                                                id: "operator_" + optionIndex,
                                                filterable: false,
                                                options: _vm.operators,
                                                placeholder: "Select operator"
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.updateOperator(
                                                    $event,
                                                    "operator",
                                                    optionIndex
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "no-options" },
                                                [
                                                  _vm._v(
                                                    "\n                                                        Select Operator\n                                                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("input-error", {
                                            attrs: {
                                              errors: _vm.errors,
                                              name: optionIndex + ".operator"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-2" },
                                        [
                                          _c("input", {
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "query_one_" + optionIndex,
                                              type: "text",
                                              name: "query_one",
                                              "input-index": optionIndex,
                                              placeholder:
                                                "Comparison Value First",
                                              required: ""
                                            },
                                            domProps: {
                                              value: _vm._.get(
                                                option,
                                                "query_one",
                                                null
                                              )
                                            },
                                            on: { input: _vm.updateOptionInput }
                                          }),
                                          _vm._v(" "),
                                          _c("input-error", {
                                            attrs: {
                                              errors: _vm.errors,
                                              name: optionIndex + ".query_one"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm._.includes(
                                        ["between", "not_between"],
                                        option.operator
                                      )
                                        ? _c(
                                            "div",
                                            { staticClass: "col-2" },
                                            [
                                              _c("input", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  id:
                                                    "query_two_" + optionIndex,
                                                  type: "text",
                                                  name: "query_two",
                                                  "input-index": optionIndex,
                                                  placeholder:
                                                    "Comparison Value Second",
                                                  required: ""
                                                },
                                                domProps: {
                                                  value: _vm._.get(
                                                    option,
                                                    "query_two",
                                                    null
                                                  )
                                                },
                                                on: {
                                                  input: _vm.updateOptionInput
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("input-error", {
                                                attrs: {
                                                  errors: _vm.errors,
                                                  name:
                                                    optionIndex + ".query_two"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-2" },
                                        [
                                          _c("input", {
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "value_one_" + optionIndex,
                                              type: "text",
                                              name: "value_one",
                                              "input-index": optionIndex,
                                              placeholder: "Value",
                                              required: ""
                                            },
                                            domProps: {
                                              value: _vm._.get(
                                                option,
                                                "value_one",
                                                null
                                              )
                                            },
                                            on: { input: _vm.updateOptionInput }
                                          }),
                                          _vm._v(" "),
                                          _c("input-error", {
                                            attrs: {
                                              errors: _vm.errors,
                                              name: optionIndex + ".value_one"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      option.column === "price"
                                        ? _c(
                                            "div",
                                            { staticClass: "col-2" },
                                            [
                                              _c("input", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  id:
                                                    "value_two_" + optionIndex,
                                                  type: "text",
                                                  name: "value_two",
                                                  "input-index": optionIndex,
                                                  placeholder: "Value",
                                                  required: ""
                                                },
                                                domProps: {
                                                  value: _vm._.get(
                                                    option,
                                                    "value_two",
                                                    null
                                                  )
                                                },
                                                on: {
                                                  input: _vm.updateOptionInput
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("input-error", {
                                                attrs: {
                                                  errors: _vm.errors,
                                                  name:
                                                    optionIndex + ".value_two"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-1" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-danger float-right",
                                            on: {
                                              click: function($event) {
                                                return _vm.removeOption(
                                                  optionIndex
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("X")]
                                        )
                                      ])
                                    ]
                                  : _vm._e()
                              ]
                            }),
                            _vm._v(" "),
                            _vm._.isEmpty(_vm.item.options)
                              ? _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success float-right",
                                      on: {
                                        click: function($event) {
                                          return _vm.addOption()
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-plus mr-2"
                                      }),
                                      _vm._v("Add New")
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("hr")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-check form-check-inline" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "notify" }
                          },
                          [
                            _c("input", {
                              staticClass: "form-check-input",
                              attrs: {
                                id: "notify",
                                type: "checkbox",
                                name: "notify"
                              },
                              domProps: {
                                checked: _vm._.get(_vm.item, "notify", false)
                              },
                              on: { input: _vm.updateCheckbox }
                            }),
                            _vm._v(
                              "\n                                        Export orders & notify\n                                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "notify" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.item.notify
                    ? _c("div", { staticClass: "col-8" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label mb-10",
                              attrs: { for: "notify_frequency" }
                            },
                            [_vm._v("Export & Notify Frequency")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "notify_frequency",
                                    type: "number",
                                    name: "notify_frequency"
                                  },
                                  domProps: {
                                    value: _vm._.get(
                                      _vm.item,
                                      "notify_frequency",
                                      null
                                    )
                                  },
                                  on: { input: _vm.updateInput }
                                }),
                                _vm._v(" "),
                                _c("input-error", {
                                  attrs: {
                                    errors: _vm.errors,
                                    name: "notify_frequency"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c(
                                  "v-select",
                                  {
                                    attrs: {
                                      value: _vm._.get(
                                        _vm.item,
                                        "notify_frequency_unit",
                                        null
                                      ),
                                      label: "label",
                                      id: "notify_frequency_unit",
                                      multiple: false,
                                      filterable: false,
                                      options: _vm.units
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.updateSelectValue(
                                          $event,
                                          "notify_frequency_unit"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("template", { slot: "no-options" }, [
                                      _vm._v(
                                        "\n                                                    freq. cycle..\n                                                "
                                      )
                                    ])
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("input-error", {
                                  attrs: {
                                    errors: _vm.errors,
                                    name: "notify_frequency_unit"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.notify
                    ? _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group required" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label mb-10",
                                attrs: { for: "emails" }
                              },
                              [_vm._v("Notify Emails")]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-select",
                              {
                                attrs: {
                                  value: _vm._.get(_vm.item, "emails", []),
                                  id: "emails",
                                  multiple: true,
                                  taggable: true,
                                  placeholder: "Add Emails"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.updateSelectValue(
                                      $event,
                                      "emails"
                                    )
                                  }
                                }
                              },
                              [
                                _c("template", { slot: "no-options" }, [
                                  _vm._v(
                                    "\n                                            Notify emails...\n                                        "
                                  )
                                ])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("input-error", {
                              attrs: { errors: _vm.errors, name: "emails" }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-12 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group text-right" },
                      [
                        _c(
                          "vue-button-spinner",
                          {
                            staticClass: "btn btn-sm btn-primary",
                            attrs: {
                              isLoading: _vm.loading,
                              disabled: _vm.loading
                            }
                          },
                          [
                            _vm._v(
                              "\n                                        Add\n                                    "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }