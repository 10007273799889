<template>
    <a class="text-dark d-block mt-1" @click="routerBack">
        <i class="fa fa-chevron-left"></i> Back
    </a>
</template>


<script>
export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        // Code...
    },
    methods: {
        routerBack() {
            this.$router.go(-1)
        }
    }
}
</script>


<style scoped>

</style>
