<template>
    <div class="container-fluid">
        <!-- Row -->
        <div class="row">
            <div class="col-lg-2 col-12 bg-light sidebar">
               <profile-sidebar/>
            </div>
            <div class="col-lg-10 col-12">
                <div class="mt-4">
                    <h4  class="mb-3">
                        My Account
                        <back-button class="float-right"></back-button>
                    </h4>
                    <hr>
                    <div  class="">
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label class="control-label mb-10" >Name :</label>
                                            {{ _.get(item, "name", null) }}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label class="control-label mb-10">Email :</label>
                                            {{ _.get(item, "email", null) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        data() {
            return {
                xprops: {
                    module: 'AccountsProfile'
                }
            }
        },
        mounted() {
        },
        created() {
            this.fetchData()
        },
        destroyed() {
            this.resetState();
        },
        computed: {
            ...mapGetters('AccountsProfile', ['item', 'loading', 'errors']),
        },
        methods: {
            ...mapActions('AccountsProfile', ['fetchData',  'resetState']),

        }
    }
</script>

<style scoped>

</style>
