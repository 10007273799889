<template>
    <div class="container-fluid">
        <!-- Row -->
        <div class="row">
            <div class="col-lg-12 col-12">
                <div class="mt-4">
                    <h4 class="">
                        Add Products
                        <back-button class="float-right"></back-button>
                    </h4>
                    <hr>
                    <div  class="">
                        <form @submit.prevent="submitForm" novalidate>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="name">Vendor</label>
                                        <input
                                            id="name"
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            :value="_.get(item, 'name', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'name'"></input-error>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="code">Code</label>
                                        <input
                                            id="code"
                                            type="text"
                                            class="form-control"
                                            name="code"
                                            :value="_.get(item, 'code', null)"
                                            @input="updateInput"
                                            required
                                        >
                                        <input-error :errors="errors" :name="'code'"></input-error>
                                    </div>
                                </div>
                                <div class="col-12" v-if="columns">
                                    <h5>Quantity Conditions</h5><hr>
                                    <div class="row">
                                        <template v-for="(option, optionIndex) in item.options">
                                            <div class="col-2">
                                                <v-select :value="option.column" @input="updateColumn($event,'column', optionIndex)" label="label" :id="`column_${optionIndex}`" :filterable="false" :options="columns" placeholder="Select operator">
                                                    <template slot="no-options">
                                                        Select Column
                                                    </template>
                                                </v-select>
                                                <input-error :errors="errors" :name="`${optionIndex}.column`"></input-error>
                                            </div>
                                            <template v-if="option.column">
                                                <div class="col-2">
                                                    <v-select :value="option.operator" @input="updateOperator($event,'operator', optionIndex)" label="label" :id="`operator_${optionIndex}`" :filterable="false" :options="operators" placeholder="Select operator">
                                                        <template slot="no-options">
                                                            Select Operator
                                                        </template>
                                                    </v-select>
                                                    <input-error :errors="errors" :name="`${optionIndex}.operator`"></input-error>
                                                </div>
                                                <div class="col-2">
                                                    <input
                                                        :id="`query_one_${optionIndex}`"
                                                        type="text"
                                                        class="form-control"
                                                        name="query_one"
                                                        :input-index="optionIndex"
                                                        :value="_.get(option, 'query_one', null)"
                                                        @input="updateOptionInput"
                                                        placeholder="Comparison Value First"
                                                        required
                                                    >
                                                    <input-error :errors="errors" :name="`${optionIndex}.query_one`"></input-error>
                                                </div>
                                                <div class="col-2" v-if="_.includes(['between', 'not_between'], option.operator)">
                                                    <input
                                                        :id="`query_two_${optionIndex}`"
                                                        type="text"
                                                        class="form-control"
                                                        name="query_two"
                                                        :input-index="optionIndex"
                                                        :value="_.get(option, 'query_two', null)"
                                                        @input="updateOptionInput"
                                                        placeholder="Comparison Value Second"
                                                        required
                                                    >
                                                    <input-error :errors="errors" :name="`${optionIndex}.query_two`"></input-error>
                                                </div>
                                                <div class="col-2">
                                                    <input
                                                        :id="`value_one_${optionIndex}`"
                                                        type="text"
                                                        class="form-control"
                                                        name="value_one"
                                                        :input-index="optionIndex"
                                                        :value="_.get(option, 'value_one', null)"
                                                        @input="updateOptionInput"
                                                        placeholder="Value"
                                                        required
                                                    >
                                                    <input-error :errors="errors" :name="`${optionIndex}.value_one`"></input-error>
                                                </div>
                                                <div class="col-2" v-if="option.column === 'price'">
                                                    <input
                                                        :id="`value_two_${optionIndex}`"
                                                        type="text"
                                                        class="form-control"
                                                        name="value_two"
                                                        :input-index="optionIndex"
                                                        :value="_.get(option, 'value_two', null)"
                                                        @input="updateOptionInput"
                                                        placeholder="Value"
                                                        required
                                                    >
                                                    <input-error :errors="errors" :name="`${optionIndex}.value_two`"></input-error>
                                                </div>
                                                <div class="col-1">
                                                    <a class="btn btn-sm btn-danger float-right" @click="removeOption(optionIndex)">X</a>
                                                </div>
                                            </template>
                                        </template>
                                        <div class="col-12" v-if="_.isEmpty(item.options)">
                                            <a class="btn btn-sm btn-success float-right" @click="addOption()"><i class="fa fa-plus mr-2"></i>Add New</a>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div class="col-4">
                                    <div class="form-check form-check-inline">
                                        <label class="control-label mb-10" for="notify">
                                            <input
                                                id="notify"
                                                type="checkbox"
                                                class="form-check-input"
                                                name="notify"
                                                :checked="_.get(item, 'notify', false)"
                                                @input="updateCheckbox"
                                            >
                                            Export orders & notify
                                        </label>
                                        <input-error :errors="errors" :name="'notify'"></input-error>
                                    </div>
                                </div>
                                <div class="col-8" v-if="item.notify">
                                    <div class="form-group">
                                        <label class="control-label mb-10" for="notify_frequency">Export & Notify Frequency</label>
                                        <div class="row">
                                            <div class="col-6">
                                                <input
                                                    id="notify_frequency"
                                                    type="number"
                                                    class="form-control"
                                                    name="notify_frequency"
                                                    :value="_.get(item, 'notify_frequency', null)"
                                                    @input="updateInput"
                                                >
                                                <input-error :errors="errors" :name="'notify_frequency'"></input-error>
                                            </div>
                                            <div class="col-6">
                                                <v-select :value="_.get(item, 'notify_frequency_unit', null)" @input="updateSelectValue($event, 'notify_frequency_unit')" label="label" id="notify_frequency_unit" :multiple="false" :filterable="false" :options="units">
                                                    <template slot="no-options">
                                                        freq. cycle..
                                                    </template>
                                                </v-select>
                                                <input-error :errors="errors" :name="'notify_frequency_unit'"></input-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12"  v-if="item.notify">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="emails">Notify Emails</label>
                                        <v-select :value="_.get(item, 'emails', [])" @input="updateSelectValue($event,'emails')" id="emails" :multiple="true" :taggable="true" placeholder="Add Emails">
                                            <template slot="no-options">
                                                Notify emails...
                                            </template>
                                        </v-select>
                                        <input-error :errors="errors" :name="'emails'"></input-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-12">
                                    <div class="form-group text-right">
                                        <vue-button-spinner
                                                class="btn btn-sm btn-primary"
                                                :isLoading="loading"
                                                :disabled="loading"
                                        >
                                            Add
                                        </vue-button-spinner>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    import Vue from "vue";
    import { mapGetters, mapActions } from 'vuex';
    import vSelect from 'vue-select';
    import debounce from 'lodash/debounce';
    Vue.component('v-select', vSelect);
    export default {
        components: {
        },
        data() {
            return {
                xprops: {
                    module: 'VendorsForm'
                },
            }
        },
        created() {
            this.configData();
        },
        computed: {
            ...mapGetters('VendorsForm', [
                'item',
                'loading',
                'errors',
                'operators',
                'units'
            ]),
            columns() {
                return [
                    {'id':'quantity', "label":"Quantity"},
                ];
            }
        },
        methods: {
            ...mapActions('VendorsForm', [
                'configData',
                'storeData',
                'setItemProperty',
                'resetErrors',
                'resetState',
                'addOption',
                'removeOption',
                'setOptionProperty'
            ]),
            submitForm() {
                this.storeData()
                    .then((response) => {
                        this.$awn.success('Vendor saved successfully.')
                    })
                    .catch((error) => {
                        this.$awn.info('OOPS! Error Occured.')
                    });
            },
            updateInput(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.value;
                this.setItemProperty({key, value});
            },
            updateCheckbox(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.checked;
                this.setItemProperty({key, value});
            },
            updateSelectValue(value, key)  {
                this.setItemProperty({key, value});
            },
            updateOptionInput(e) {
                let key = e.target.getAttribute("name");
                let index = e.target.getAttribute("input-index");
                let value = e.target.value;
                this.setOptionProperty({index, key, value});
            },
            updateOperator(value, key, index)  {
                if(_.isObject(value)){
                    value = value.id;
                    this.setOptionProperty({index, key, value});
                }else{
                    this.setOptionProperty({index, key, value});
                }
            },
            updateColumn(value, key, index)  {
                if(_.isObject(value)){
                    value = value.id;
                    this.setOptionProperty({index, key, value});
                }else{
                    this.setOptionProperty({index, key, value});
                }
            },
        }
    }
</script>

<style scoped>

</style>
