<template>
    <div class="container-fluid mr-3">
        <div style="padding-bottom: 150px;" class="know_industry_cov full" id="coverage">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Manage jobs</h5>
                            <p class="card-text">Add/remove csv to sync inventory.</p>
                            <router-link :to="{ name: 'catalog.jobs'}"  class="btn btn-success btn-sm"><i class="fa fa-list"></i> List Jobs</router-link>
                            <router-link :to="{ name: 'catalog.jobs.create'}"  class="btn btn-success btn-sm"><i class="fa fa-plus"></i> Add Job</router-link>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">List Inventory</h5>
                            <p class="card-text">Add/remove items to/from inventory.</p>
                            <router-link :to="{ name: 'catalog.products'}"  class="btn btn-success btn-sm"><i class="fa fa-list"></i> List Items</router-link>
                            <router-link :to="{ name: 'catalog.products.create'}"  class="btn btn-success btn-sm"><i class="fa fa-plus"></i> Add Item</router-link>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Manage Profile</h5>
                            <p class="card-text">Update profile details, change password.</p>
                            <router-link class="btn btn-success btn-sm" :to="{ name: 'account'}"><i class="fa mr-1 fa-user"></i> Manage Profile</router-link>
                            <router-link  class="btn btn-success btn-sm" :to="{ name: 'account.editProfile'}"><i class="fa mr-1 fa-user"></i> Edit Profile</router-link>
                            <router-link class="btn btn-success btn-sm" :to="{ name: 'account.changePassword'}"><i class="fa mr-1 fa-btn fa-user-secret"></i> Change password</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Manage Settings</h5>
                            <p class="card-text">Update application wide configuration.</p>
                            <router-link class="btn btn-success btn-sm" :to="{ name: 'settings.general'}"><i class="fa mr-1 fa-cogs"></i> Manage Settings</router-link>
                            <router-link class="btn btn-success btn-sm" :to="{ name: 'catalog.downloads'}"><i class="fas fa-cloud-download-alt ml-3"></i> Downloads</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped>

</style>
