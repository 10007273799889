<template>
    <div class="container-fluid">

        <!-- Row -->
        <div class="row">
            <div class="col-lg-2 col-12 bg-light sidebar">
               <profile-sidebar/>
            </div>
            <div class="col-lg-10 col-12">
                <div class="mt-4">
                    <h4  class="mb-3">
                         Edit Picture
                        <back-button class="float-right"></back-button>
                    </h4>
                    <hr>
                    <div class="">
                        <div class="large-12 medium-12 small-12 cell">
                           <form class="form-inline mb-2">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="pic">Profile pic &nbsp;</label>
                                        <input id="pic" type="file" ref="pic" v-on:change="handleFileUpload()"/>
                                    </div>
                                </div>
                               <div class="col">
                                   <b-progress :value="100" variant="success" show-progress animated v-if="loading"></b-progress>
                               </div>
                            </form>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        data() {
            return {
                xprops: {
                    module: 'AccountsEditPicture'
                }
            }
        },
        mounted() {
        },
        created() {
            this.setQuery(this.query);
            this.fetchData();
        },
        destroyed() {
            this.resetState();
        },
        computed: {
            ...mapGetters('AccountsEditPicture', ['file', 'items', 'loading']),
        },
        watch: {
            query: {
                handler(query) {
                    this.setQuery(query)
                },
                deep: true
            }
        },
        methods: {
            ...mapActions('AccountsEditPicture', ['setQuery', 'resetState', 'setFile', 'uploadFile']),


            handleFileUpload(){
                this.setFile(this.$refs.pic.files[0]);
                this.uploadFile()
                    .then((response) => {
                        this.$awn.success('Your file uploaded successfully.')
                    })
                    .catch((error) => {
                        this.$awn.info('OOPS! Error Occured.')
                    });
            }
        }
    }
</script>

<style scoped>

</style>
