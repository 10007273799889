var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid mr-3" }, [
    _c(
      "div",
      {
        staticClass: "know_industry_cov full",
        staticStyle: { "padding-bottom": "150px" },
        attrs: { id: "coverage" }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Manage jobs")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v("Add/remove csv to sync inventory.")
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { to: { name: "catalog.jobs" } }
                    },
                    [
                      _c("i", { staticClass: "fa fa-list" }),
                      _vm._v(" List Jobs")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { to: { name: "catalog.jobs.create" } }
                    },
                    [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" Add Job")]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("List Inventory")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v("Add/remove items to/from inventory.")
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { to: { name: "catalog.products" } }
                    },
                    [
                      _c("i", { staticClass: "fa fa-list" }),
                      _vm._v(" List Items")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { to: { name: "catalog.products.create" } }
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" Add Item")
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Manage Profile")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v("Update profile details, change password.")
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { to: { name: "account" } }
                    },
                    [
                      _c("i", { staticClass: "fa mr-1 fa-user" }),
                      _vm._v(" Manage Profile")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { to: { name: "account.editProfile" } }
                    },
                    [
                      _c("i", { staticClass: "fa mr-1 fa-user" }),
                      _vm._v(" Edit Profile")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { to: { name: "account.changePassword" } }
                    },
                    [
                      _c("i", { staticClass: "fa mr-1 fa-btn fa-user-secret" }),
                      _vm._v(" Change password")
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Manage Settings")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v("Update application wide configuration.")
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { to: { name: "settings.general" } }
                    },
                    [
                      _c("i", { staticClass: "fa mr-1 fa-cogs" }),
                      _vm._v(" Manage Settings")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { to: { name: "catalog.downloads" } }
                    },
                    [
                      _c("i", {
                        staticClass: "fas fa-cloud-download-alt ml-3"
                      }),
                      _vm._v(" Downloads")
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }