var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-2 col-12 bg-light sidebar" },
        [_c("profile-sidebar")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-10 col-12" }, [
        _c("div", { staticClass: "mt-4" }, [
          _c(
            "h4",
            { staticClass: "mb-3" },
            [
              _vm._v("\n                    My Account\n                    "),
              _c("back-button", { staticClass: "float-right" })
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", {}, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-6 col-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label mb-10" }, [
                        _vm._v("Name :")
                      ]),
                      _vm._v(
                        "\n                                        " +
                          _vm._s(_vm._.get(_vm.item, "name", null)) +
                          "\n                                    "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-6 col-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label mb-10" }, [
                        _vm._v("Email :")
                      ]),
                      _vm._v(
                        "\n                                        " +
                          _vm._s(_vm._.get(_vm.item, "email", null)) +
                          "\n                                    "
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }