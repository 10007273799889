<template>
    <div class="container-fluid">
        <!-- Row -->
        <div class="row">
            <div class="col-lg-2 col-12 bg-light sidebar">
               <profile-sidebar/>
            </div>
            <div class="col-lg-10 col-12">
                <div class="mt-4">
                    <h4  class="mb-3">
                        Edit Profile
                        <back-button class="float-right"></back-button>
                    </h4>
                    <hr>
                    <div class="">
                        <div  class="">
                            <form @submit.prevent="submitForm" novalidate>
                                <div class="row">
                                    <div class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label class="control-label mb-10" for="name">Name</label>
                                            <input
                                                id="name"
                                                type="text"
                                                class="form-control"
                                                name="name"
                                                :value="_.get(item, 'name', null)"
                                                @input="updateInput"
                                                required
                                            >
                                            <input-error :errors="errors" :name="'name'"></input-error>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label class="control-label mb-10" for="email">Email</label>
                                            <input
                                                id="email"
                                                type="text"
                                                class="form-control"
                                                name="email"
                                                :value="_.get(item, 'email', null)"
                                                @input="updateInput"
                                                required
                                            >
                                            <input-error :errors="errors" :name="'email'"></input-error>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group text-right">
                                            <vue-button-spinner
                                                class="btn btn-primary"
                                                :isLoading="loading"
                                                :disabled="loading"
                                            >
                                                Update
                                            </vue-button-spinner>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        data() {
            return {
                xprops: {
                    module: 'AccountsEditProfile'
                }
            }
        },
        mounted() {
        },
        created() {
            this.fetchData()
        },
        destroyed() {
            this.resetState();
        },
        watch: {
            "$route.params.id": function() {
                this.resetState();
                this.fetchData()
            }
        },
        computed: {
            ...mapGetters('AccountsEditProfile', ['item', 'loading', 'errors']),
        },
        methods: {
            ...mapActions('AccountsEditProfile', ['updateData', 'fetchData', 'setItemProperty', 'resetErrors', 'resetState']),
            submitForm() {
                this.updateData()
                    .then((response) => {
                        this.$awn.success('User Update successfully.')
                    })
                    .catch((error) => {
                        this.$awn.info('OOPS! Error Occured.')
                    });
            },
            updateInput(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.value;
                this.setItemProperty({key, value});
            },
        }
    }
</script>

<style scoped>

</style>
