<template>
    <div class="container-fluid">
        <!-- Row -->
        <div class="row">
            <div class="col-lg-2 col-12 bg-white">
               <settings-sidebar/>
            </div>
            <div class="col-lg-10 col-12">
                <div class="mt-3">
                    <h4  class="mb-3">
                     General Settings
                        <back-button class="float-right"></back-button>
                    </h4>
                    <hr>
                    <div  class="">
                        <div  class="">
                        <form @submit.prevent="submitForm" novalidate>
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="site-title">App Name</label>
                                        <input
                                                id="site-title"
                                                type="text"
                                                class="form-control"
                                                name="site-title"
                                                :value="_.get(item, 'site-title', null)"
                                                @input="updateInput"
                                                required
                                        >
                                        <input-error :errors="errors" :name="'site-title'"></input-error>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="site-email">Admin Email</label>
                                        <input
                                                id="site-email"
                                                type="text"
                                                class="form-control"
                                                name="site-email"
                                                :value="_.get(item, 'site-email', null)"
                                                @input="updateInput"
                                                required
                                        >
                                        <input-error :errors="errors" :name="'site-email'"></input-error>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="site-description">About App</label>
                                        <textarea
                                            id="site-description"
                                            type="text"
                                            class="form-control"
                                            name="site-description"
                                            :value="_.get(item, 'site-description', null)"
                                            @input="updateInput"
                                            required
                                        ></textarea>
                                        <input-error :errors="errors" :name="'site-description'"></input-error>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="default-timezone">TimeZone</label>
                                        <v-select label="zone" :value="_.get(item, 'default-timezone', null)" @input="updateTimeZone" id="default-timezone" :filterable="true" :options="timezones" placeholder="Select timezone">
                                            <template slot="no-options">
                                                type to search timezones..
                                            </template>
                                        </v-select>
                                        <input-error :errors="errors" :name="'default-timezone'"></input-error>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group required">
                                        <label class="control-label mb-10" for="records-per-page">Records per page</label>
                                        <input
                                                id="records-per-page"
                                                type="text"
                                                class="form-control"
                                                name="records-per-page"
                                                :value="item['records-per-page']"
                                                @input="updateInput"
                                                required
                                        >
                                        <input-error :errors="errors" :name="'records-per-page'"></input-error>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-check form-group">
                                        <input
                                            id="download-orders"
                                            type="checkbox"
                                            class="form-check-input"
                                            name="download-orders"
                                            :true-value="1"
                                            :false-value="0"
                                            :checked="downloadEnabled"
                                            @input="updateCheckbox"
                                            required
                                        >
                                        <label class="form-check-label mb-10" for="download-orders">Automatically download orders to csv?</label>
                                        <small class="form-text text-muted"> If checked orders will be downloaded every {{_.get(item, 'download-frequency', 2)}} Hours</small>
                                        <input-error :errors="errors" :name="'download-orders'"></input-error>
                                    </div>
                                </div>
                                <div class="row" v-if="downloadEnabled">
                                    <div class="col-6">
                                        <div class="form-group required">
                                            <label class="control-label mb-10" for="download-frequency">Download Frequency</label>
                                            <input
                                                id="download-frequency"
                                                type="number"
                                                class="form-control"
                                                name="download-frequency"
                                                :value="_.get(item, 'download-frequency', 2)"
                                                @input="updateInput"
                                                required
                                            >
                                            <input-error :errors="errors" :name="'download-frequency'"></input-error>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-check form-group">
                                            <input
                                                id="email-downloaded-orders"
                                                type="checkbox"
                                                class="form-check-input"
                                                name="email-downloaded-orders"
                                                :true-value="1"
                                                :false-value="0"
                                                :checked="emailDownloaded"
                                                @input="updateCheckbox"
                                                required
                                            >
                                            <label class="form-check-label mb-10" for="download-orders">Email downloaded orders?</label>
                                            <small class="form-text text-muted"> If checked downloaded orders will be emailed.</small>
                                            <input-error :errors="errors" :name="'email-downloaded-orders'"></input-error>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group required">
                                            <label class="control-label mb-10" for="orders-email">Download alert email</label>
                                            <input
                                                id="orders-email"
                                                type="email"
                                                class="form-control"
                                                name="orders-email"
                                                :value="_.get(item, 'orders-email', null)"
                                                @input="updateInput"
                                                required
                                            >
                                            <small class="form-text text-muted">Enter comma(,) separated emails for multiple email addresses.</small>
                                            <input-error :errors="errors" :name="'orders-email'"></input-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12">
                                    <div class="form-group text-right">
                                        <vue-button-spinner
                                                class="btn btn-sm btn-primary"
                                                :isLoading="loading"
                                                :disabled="loading"
                                        >
                                            Update
                                        </vue-button-spinner>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- /Row -->
    </div>
</template>

<script>
    import Vue from "vue";
    import { mapGetters, mapActions } from 'vuex';
    import vSelect from 'vue-select';
    Vue.component('v-select', vSelect);
     export default {
        data() {
            return {
                xprops: {
                    module: 'SettingsForm'
                }
            }
        },
        mounted() {
        },
        created() {
            this.fetchOptions("general");
            this.fetchData("general")
        },
        destroyed() {
            this.resetState();
        },
        computed: {
            ...mapGetters('SettingsForm', ['item', 'loading', 'errors', 'timezones']),
            downloadEnabled()
            {
                let value = _.get(this.item, 'download-orders', false);
                return (value === '1' || value === true || value === 1);
            },
            emailDownloaded()
            {
                let value = _.get(this.item, 'email-downloaded-orders', false);
                return (value === '1' || value === true || value === 1);
            }
        },
        methods: {
            ...mapActions('SettingsForm', ['updateData', 'fetchData', 'fetchOptions', 'setItemProperty'
            , 'resetErrors', 'resetState']),
            submitForm() {
                this.updateData("general")
                    .then((response) => {
                        this.$awn.success('Setting Update successfully.')
                    })
                    .catch((error) => {
                        this.$awn.info('OOPS! Error Occured.')
                    });
            },
            updateCheckbox(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.checked;
                this.setItemProperty({key: key, value: value});
            },
            updateTimeZone(event) {
                this.setItemProperty({key: 'default-timezone', value: event.zone});
            },
            updateInput(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.value;
                this.setItemProperty({key, value});
            }
        }
    }
</script>

<style scoped>

</style>
