var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-12" }, [
        _c("div", { staticClass: "mt-4" }, [
          _c(
            "h4",
            {},
            [
              _vm._v(
                "\n                    Edit Product\n                    "
              ),
              _c("back-button", { staticClass: "float-right" })
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "form",
              {
                attrs: { novalidate: "" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submitForm($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "name" }
                          },
                          [_vm._v("Title")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "name",
                            type: "text",
                            name: "name",
                            required: ""
                          },
                          domProps: {
                            value: _vm._.get(_vm.item, "name", null)
                          },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "name" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "sku" }
                          },
                          [_vm._v("SKU")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "sku",
                            type: "text",
                            name: "sku",
                            required: ""
                          },
                          domProps: { value: _vm._.get(_vm.item, "sku", null) },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "sku" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "vendor" }
                          },
                          [_vm._v("Vendor")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "vendor",
                            type: "text",
                            name: "vendor",
                            required: ""
                          },
                          domProps: {
                            value: _vm._.get(_vm.item, "vendor", null)
                          },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "vendor" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "internalId" }
                          },
                          [_vm._v("Internal ID")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "internalId",
                            type: "text",
                            name: "internalId",
                            required: ""
                          },
                          domProps: {
                            value: _vm._.get(_vm.item, "internalId", null)
                          },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "sku" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "price" }
                          },
                          [_vm._v("Price")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "price",
                            type: "text",
                            name: "price",
                            required: ""
                          },
                          domProps: {
                            value: _vm._.get(_vm.item, "price", null)
                          },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "price" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "quantity" }
                          },
                          [_vm._v("Quantity")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "quantity",
                            type: "text",
                            name: "quantity",
                            required: ""
                          },
                          domProps: {
                            value: _vm._.get(_vm.item, "quantity", null)
                          },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "quantity" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "productId" }
                          },
                          [_vm._v("Product ID")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "productId",
                            type: "text",
                            name: "productId",
                            required: ""
                          },
                          domProps: {
                            value: _vm._.get(_vm.item, "productId", null)
                          },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "productId" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "variantId" }
                          },
                          [_vm._v("Variant ID")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "variantId",
                            type: "text",
                            name: "variantId",
                            required: ""
                          },
                          domProps: {
                            value: _vm._.get(_vm.item, "variantId", null)
                          },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "variantId" }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group required" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label mb-10",
                            attrs: { for: "inventoryId" }
                          },
                          [_vm._v("Inventory ID")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            id: "inventoryId",
                            type: "text",
                            name: "inventoryId",
                            required: ""
                          },
                          domProps: {
                            value: _vm._.get(_vm.item, "inventoryId", null)
                          },
                          on: { input: _vm.updateInput }
                        }),
                        _vm._v(" "),
                        _c("input-error", {
                          attrs: { errors: _vm.errors, name: "inventoryId" }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-12 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group text-right" },
                      [
                        _c(
                          "vue-button-spinner",
                          {
                            staticClass: "btn btn-sm btn-primary",
                            attrs: {
                              isLoading: _vm.loading,
                              disabled: _vm.loading
                            }
                          },
                          [
                            _vm._v(
                              "\n                                        Update\n                                    "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }